import React from "react";
import DepartmentsCarousel from "./DepartmentsCarousel";

export default function CarouselHold() {
	return (
		<div className="carousel-hold">
			<h1 className="title uppercase">Departments</h1>
			<DepartmentsCarousel />
		</div>
	);
}
