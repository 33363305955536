import React from "react";
import { useEffect } from "react";
import AliceCarousel from "react-alice-carousel";
import "./ck-content-styles.css";
import "./4cStyles.css";
import axios from "axios";
import { useState } from "react";
import { Buffer } from "buffer";
import {
	API_BASE_URL,
	FILE_BASE_URL,
	IMG_BASE_URL,
	PROFILE_IMG_BASE_URL,
} from "../../config";
import Footer from "../Footer";
import { Link } from "react-router-dom";

export default function FourCPage({ subdomainName }) {
	const [rawHTML, setRawHTML] = useState();
	const [convenors, setConvenors] = useState(null);
	const [studentMembers, setStudentMembers] = useState(null);
	const [logo, setLogo] = useState("");
	const [mission, setMission] = useState(null);
	const [vision, setVision] = useState(null);
	const [about, setAbout] = useState("");
	const [subTitle, setSubTitle] = useState("");
	const [name, setName] = useState("");
	const [components, setComponents] = useState(null);
	const [imgSlides, setImgSlides] = useState([]);

	const componentsMap = {
		img_slider:
			(imgSlides && imgSlides.length > 1 && (
				<div className="justify-center flex">
					<div className="fourC-slider-container">
						<AliceCarousel
							autoPlay
							autoPlayInterval={2000}
							infinite
							animationDuration={2000}
							items={imgSlides}
							disableDotsControls
						></AliceCarousel>
					</div>
				</div>
			)) ||
			(imgSlides && imgSlides.length === 1 && (
				<div className="justify-center flex">
					<div className="fourC-slider-container">
						<AliceCarousel
							items={imgSlides}
							disableDotsControls
						></AliceCarousel>
					</div>
				</div>
			)),
		members_container: (
			<div>
				<div className="fourC-members-container">
					<div className="text-4xl my-6 sm:text-6xl text-center text-[#ad0303] sm:my-10">
						MEET THE MEMBERS!
					</div>
					<div className="fourC-convenors-container">
						{convenors &&
							convenors.map((element) => {
								return (
									<div className="fourC-convenor-individual-container">
										<img
											src={PROFILE_IMG_BASE_URL + element["pp_file_name"]}
										></img>
										<div className="fourC-convenor-text">
											<div className="fourC-convenor-name">
												{element["faculty_name"]}
											</div>
											<div className="fourC-convenor-qualification">
												{element["qualification"]}
											</div>
											<div className="fourC-convenor-role">
												{element["role"]}
											</div>
										</div>
									</div>
								);
							})}
					</div>
					{studentMembers && (
						<div className="fourC-student-members-container">
							<ul>
								<h2 class="text-xl sm:text-xl font-bold  text-[#ad0303] ">
									Student Members
								</h2>
								{studentMembers &&
									studentMembers.map((element) => {
										return <li class="mx-5 list-disc">{element}</li>;
									})}
							</ul>
						</div>
					)}
				</div>
			</div>
		),
		details: (
			<div className="fourC-details">
				<div className="fourC-about">
					<div className="text-3xl sm:text-4xl font-bold sm:mb-5 ml-4  text-[#ad0303] ">
						About the {name}
					</div>
					<p>{about}</p>
				</div>
				<div className="fourC-mission-vision">
					<div className="fourC-mission">
						<div className="text-2xl sm:text-4xl font-bold ml-3 sm:mb-5  text-[#ad0303] ">
							Mission
						</div>
						{mission &&
							mission.map((element) => {
								return <li>{element}</li>;
							})}
					</div>
					<div className="fourC-vision">
						<div className="text-2xl sm:text-4xl font-bold ml-3 sm:mb-5  text-[#ad0303]">
							Vision
						</div>
						{vision &&
							vision.map((element) => {
								return <li>{element}</li>;
							})}
					</div>
				</div>
			</div>
		),
		custom_content: rawHTML && rawHTML.length !== 0 && (
			<div className="fourC-custom-content">
				<div className="ck-content">
					<div dangerouslySetInnerHTML={{ __html: rawHTML }}></div>
				</div>
			</div>
		),
	};

	useEffect(
		() => {
			async function fetch4Cdata() {
				try {
					const response = await axios.get(
						API_BASE_URL + `FourC.php?four_c_subdomain_name=` + subdomainName
					);
					const obj = response.data;
					console.log(obj);
					setComponents(obj["components"]);
					setRawHTML(Buffer.from(obj["custom_content"], "base64"));
					setLogo(obj["mainValues"]["four_c_logo"]);
					setStudentMembers(obj["student_members"]);
					setMission(obj["mission"]);
					setVision(obj["vision"]);
					setAbout(obj["mainValues"]["about"]);
					setSubTitle(obj["mainValues"]["four_c_sub_title"]);
					setName(obj["mainValues"]["four_c_name"]);
					setConvenors(obj["convenors_info"]);
				} catch (e) {
					console.error(e);
				}
			}

			async function fetch4Cgallery() {
				const response = await axios.get(
					API_BASE_URL + `Images.php?category=` + subdomainName
				);
				const obj = response.data;
				const newslides = obj.map((element) => (
					<div class="fourC-slider-item">
						<img
							src={FILE_BASE_URL + "img_gallery/" + element["img_name"]}
						></img>
						<p>{element["description"]}</p>
					</div>
				));

				console.log(newslides);
				setImgSlides(newslides);
			}
			fetch4Cdata();
			fetch4Cgallery();
		},
		/*PUT THE 4C SUB DOMAIN HERE*/ []
	);

	console.log(convenors);
	console.log(vision);
	console.log("SUB" + subTitle);

	var header = <></>;

	if (logo === "" || logo == null) {
		header = (
			<section
				style={{
					justifyContent: "space-between",
					width: "100%",
					backgroundColor: "#9b1200",
					boxShadow: "5px 5px 500px #9b1200",
				}}
			>
				<div class="fourC-header">
					<Link
						to="/"
						style={{
							textDecoration: "none",
							maxWidth: "150px",
						}}
					>
						<img src="https://beta.ptuniv.edu.in/assets/images/ptu-logo.png" />
					</Link>
					<div
						class="fourC-club-name"
						style={{ width: "100%", alignSelf: "center" }}
					>
						<h3>{name}</h3>
						<p>
							<i>Puducherry Technological University</i>
						</p>
					</div>
				</div>
			</section>
		);
	} else {
		header = (
			<section
				style={{
					justifyContent: "space-between",
					width: "100%",
					backgroundColor: "#9b1200",
					boxShadow: "5px 5px 500px #9b1200",
				}}
			>
				<div class="fourC-header">
					<Link to="/" style={{ textDecoration: "none", maxWidth: "150px" }}>
						<img src="https://beta.ptuniv.edu.in/assets/images/ptu-logo.png" />
					</Link>
					<div class="fourC-club-name">
						<h3>{name}</h3>
						<p>
							<i>Puducherry Technological University</i>
						</p>
					</div>
					<Link to="" style={{ textDecoration: "none", maxWidth: "150px" }}>
						<img src={FILE_BASE_URL + "four_c_logos/" + logo}></img>
					</Link>
				</div>
			</section>
		);
	}
	return (
		<div className="justify-center items-center">
			{header}
			<div className="max-w-[1280px] mx-auto">
				{components &&
					components.map((element) => {
						return componentsMap[element];
					})}
			</div>
			{/* to do
			<div className="fourC-news-events-container">
				<div className="text-4xl font-bold mb-5">News and Events</div>
				<div className="flex flex-row w-[100%]">
					<div className="fourC-news">
						<div className="text-4xl font-bold ml-3 mb-5">News</div>
						<p>News content1</p>
						<p>News content2</p>
						<p>News content3</p>
						<p>News content4</p>
					</div>
					<div className="fourC-events">
						<div className="text-4xl font-bold ml-3 mb-5">Events</div>
						<p>Events content1</p>
						<p>Events content2</p>
						<p>Events content3</p>
						<p>Events content4</p>
					</div>
					</div>
						
			</div>
			*/}
			{/*to do below*/}
			<div className="fourC-articles-container"></div>
			<Footer />
		</div>
	);
}
