import React from "react";

import { FILE_BASE_URL } from "../../config";
import CurriculumSyllabus from "../AcademicCurriculumSyllabus/CurruculumSyllabus";

const REGULATION_FILE_BASE_URL = FILE_BASE_URL + "academic-regulation/";

const Regulations = () => {
	return (
		<div className="py-7 mb-9">
			<CurriculumSyllabus
				title="ACADEMIC REGULATIONS"
				syllabus={regulationsData}
			/>
		</div>
	);
};

const regulationsData = [
	{
		departmentName: "B.Tech",
		syllabus: {
			ug: [
				{
					text: "2014-15",
					href: `${REGULATION_FILE_BASE_URL}BTech_Regulations_1415.pdf`,
				},
				{
					text: "2018-19",
					href: `${REGULATION_FILE_BASE_URL}BTech_Regulations_1819.pdf`,
				},
				{
					text: "2020-21",
					href: `${REGULATION_FILE_BASE_URL}BTech_Regulations_2021.pdf`,
				},
			],
		},
	},
	{
		departmentName: "M.Tech",
		syllabus: {
			pg: [
				{
					text: "2015-16",
					href: `${REGULATION_FILE_BASE_URL}MTech_Regulations_1516.pdf`,
				},
				{
					text: "2020-21",
					href: `${REGULATION_FILE_BASE_URL}MTech_Regulations_2021.pdf`,
				},
			],
		},
	},
	{
		departmentName: "MCA",
		syllabus: {
			pg: [
				{
					text: "2015-16",
					href: `${REGULATION_FILE_BASE_URL}MCA_Regulations_1516.pdf`,
				},
				{
					text: "2020-21",
					href: `${REGULATION_FILE_BASE_URL}MCA_Regulations_2021.pdf`,
				},
			],
		},
	},
	{
		departmentName: "MSc",
		syllabus: {
			pg: [
				{
					text: "2020-21",
					href: `${REGULATION_FILE_BASE_URL}MSc_Regulations_2021.pdf`,
				},
			],
		},
	},
	{
		departmentName: "MBA",
		syllabus: {
			pg: [
				{
					text: "2020-21",
					href: `${REGULATION_FILE_BASE_URL}MBA_IEVD_Regulations_2021.pdf`,
				},
				{
					text: "2021-22",
					href: `${REGULATION_FILE_BASE_URL}MBA_IB_Regulations_2122.pdf`,
				},
			],
		},
	},
];

export default Regulations;
