import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
	data: {},
};

const modalSlice = createSlice({
	name: "modalSlice",
	initialState,
	reducers: {
		setModalVisibility(state, { payload: { name, visibility } }) {
			state.data[name] = visibility;
		},
	},
});

export const { setModalVisibility } = modalSlice.actions;

export default modalSlice.reducer;
