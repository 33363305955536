import React from 'react'
import { documents } from './contacts'

const Maildiv = ({ value }) => {
  return (
   <>
    <div className='col-span-5 mx-3'>
         <h1 className='m-1 text-red-500'>Contacts</h1>
         <p className='m-1 mb-4'>Select the desired authority from the right. To which you want to send your queries.</p>
         <hr />
        <div className='my-4 w-full sm:pr-9' id="contact-form">
           <div className="my-3 flex items-center justify-left">
                 <label htmlFor="Contact-input-name" className="hidden sm:w-40 sm:block">Name</label>
                 <input type="text" 
                        id="Contact-input-name" 
                        className="bg-red-50 sm:min-w-[85%] min-w-[100%] px-2 py-1 mt-2 border border-red-100" 
                        placeholder="steve" required />
           </div>
           <div className="my-3 flex items-center justify-left">
               <label htmlFor="Contact-input-identification" className="hidden sm:w-40 sm:block">Reg No</label>
               <input type="text" 
                      id="Contact-input-identification" 
                      className="bg-red-50 sm:min-w-[85%] min-w-[100%] px-2 py-1 mt-2 border border-red-100" 
                      placeholder="12CSXXX" required />
           </div>
           <div className="my-3 flex items-center justify-left">
               <label htmlFor="Contact-input-identification" className="hidden sm:w-40 sm:block">To</label>
               <input type="text" 
                      id="Contact-input-identification" 
                      className="bg-red-50 sm:min-w-[85%] min-w-[100%] px-2 py-1 mt-2 border border-red-100" 
                      placeholder="12CSXXX" 
                      value={documents[value].email} 
                      disabled />
           </div>
           <div className="my-3 flex items-center justify-left">
               <label htmlFor="Contact-input-email" className="hidden sm:w-40 sm:block">Email</label>
               <input type="text" 
                      id="Contact-input-email" 
                      className="bg-red-50 sm:min-w-[85%] min-w-[100%] px-2 py-1 mt-2 border border-red-100" 
                      placeholder="steve@example.com" required />
           </div>
           <div className="my-3 flex items-center justify-left">
               <label htmlFor="Contact-input-phoneNo" className="hidden sm:w-40 sm:block">Phone.no</label>
               <input type="number" 
                      id="Contact-input-phoneNo" 
                      className="bg-red-50 sm:min-w-[85%] min-w-[100%] px-2 py-1 mt-2  border border-red-100" 
                      placeholder="879-XXXX-908" required />
           </div>
           <div className="my-5 flex items-center justify-left">
               <label htmlFor="Contact-input-phoneNo" className="hidden sm:w-40 sm:block">Message</label>
               <textarea cols="30" 
                         rows="4" 
                         className="bg-red-50 sm:min-w-[85%] min-w-[100%] px-2 py-1  border border-red-100" 
                         placeholder="Enter text here" required></textarea>
           </div>
           <div className='w-[100%] w-fill px-8 bg-red-800 hover:bg-red-600 text-white py-2 text-center'>
             <button type="submit" formAction="contact-form" className="">
                 SUBMIT
             </button>
           </div>
        </div>
    </div>
  </>
  )
}

export default Maildiv