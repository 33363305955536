import Footer from "../../components/Footer";
import Header from "../../components/Header";
import usePageTitle from "../../hooks/usePageTitle";
function SGR() {
	usePageTitle("Students' Grievances and Redressal Cell");
	return (
		<div>
			<Header />
			<div className="p-2" style={{ margin: "2rem auto", maxWidth: 1200 }}>
				<h2 className="uppercase mb-4 font-bold">
					Students' Grievances and Redressal Cell
				</h2>
				<p className="pb-2">
					Grievances, if any related to COVID-19, teaching-learning process,
					examinations and other matter may be mailed to the Convenor, Students'
					Grievances and Redressal Cell (SGRC) at{" "}
					<a
						href="mailto:grc@ptuniv.edu.in"
						className="underline"
						style={{ color: "blue" }}
					>
						grc@ptuniv.edu.in
					</a>
              </p>
              
              <p className="pb-2"><center><b>Students’ Grievance Redressal Committee</b></center></p>
<table align="center" border="1">
  <tr><td width="50%"><b>Name</b></td><td width="25%"><b>Role</b></td><td width="25%"><b>E-mail</b></td></tr>
<tr>

<td>Dr. RM. Chandrasekaran, Professor(Rtd.), <br />CSE, Annamalai University, Chidambaram   </td><td><a href="https://cms.ptuniv.edu.in/file_contents/Order-Appt. of Ombudsperson.pdf" title="click here to see appointment order..." target="omb">Ombudsperson</a>          </td><td>aurnc60@gmail.com</td>

</tr>
<tr>
<td>Dr. E. Ilavarasan, Professor, CSE </td><td>Convenor</td><td>eilavarasan@ptuniv.edu.in</td>

</tr><tr>

<td> Dr. B. Mahesh Kumar, Professor, EEE     </td><td> Member</td><td>bmk@ptuniv.edu.in </td>
</tr><tr>
<td>Dr. M.S. Anbarasi, Professor, IT </td><td>Member</td><td>anbarasims@ptuniv.edu.in</td>

</tr><tr>
<td>Dr. S. Tamilselvan, Professor, ECE</td><td>Member</td><td>tamilselvan@ptuniv.edu.in</td>
</tr><tr>
<td>Dr. R. Sridar, Professor and Head, Chem. Engg. </td><td>Member</td><td>sivakamisridar9@ptuniv.edu.in</td>
</tr><tr>
<td>Ms. P. Uma Maheswari ,III Year  B.Tech(CSE)</td><td>Member</td><td>umamaheswari1634@ptuniv.edu.in</td>
</tr></table>

				
				<iframe
					title="form"
					src="https://docs.google.com/forms/d/e/1FAIpQLSdqdB8JGC1M-KHnwmC05puSBPLzDq6O4scr3l_LAK_ws9KcbA/viewform?embedded=true"
					width="640"
					height="2112"
					frameborder="0"
					marginheight="0"
					marginwidth="0"
				>
					Loading…
				</iframe>
		<br></br>
		<br></br>
				<h2 className="uppercase mb-4 font-bold">
				Persons with Disabilities(PwDs) grievances redressal Cell
				</h2>
				
				<iframe
					title="form"
					src="https://docs.google.com/forms/d/e/1FAIpQLSdV9RTtivks6L91dTAkHWBgZADSrq5NC2zUsvbiQ_4LSA5ANg/viewform?embedded=true"
					width="640"
					height="750"
					frameborder="0"
					marginheight="0"
					marginwidth="0"
				>
					Loading…
				</iframe>
			</div>
			<Footer />
		</div>
	);
}

export default SGR;
