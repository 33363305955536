import { FILE_BASE_URL, SYLLABUS_BASE_URL } from "../../../config";

import Image from "../../../../src/components/assets/MECH_HOD.jpg";

import Dimg from "../../../../src/components/assets/mech.jpg";

export const ME = {
	basePath: "/me",

	navLinks: [
		{
			to: "/about",
			text: "DEPARTMENT",
		},
		{
			to: "/faculty",
			text: "FACULTY",
		},
		{
			to: "/syllabus",
			text: "CURRICULUM",
		},
		{
			href: FILE_BASE_URL + "me-research.html",
			text: "RESEARCH",
		},
		{
			to: "/facilities",
			text: "FACILITIES",
		},
	],

	About: {
		DeptName: "Mechanical engineering",
		ShortName: "ME",
		LongName: "DEPARTMENT OF Mechanical engineering",

		DeptImg: Dimg,

		count: [
			{
				name: "B.Tech Mechanical Students",
				count: 230,
			},
			{
				name: "B.Tech Mechatronics Students",
				count: 223,
			},
			{
				name: "M.Tech Energy Technology",
				count: 30,
			},
			{
				name: "M.Tech PDM",
				count: 14,
			},
		],

		about: [
			"The Department of Mechanical Engineering offers B. Tech., M. Tech., and Ph. D Programmes. The Department of Mechanical Engineering has become the QIP centre in the year 2013. Highly qualified, experienced and committed faculty members significantly contribute to the growth of the college in general and the Department in particular. The UG course of the department is accreted by NBA for a period of 5 years. The Department has excellent laboratory facilities along with state-of-the-art computing facilities and library. The Department offers world class training both in theory and practice to the students apart from providing consultancy services to the local industries.",
		],

		vision: [
			{
				point:
					"To produce dynamic, vibrant, demand driven and quality conscious human resource through consistent and sustained development ensuring highest standards of technological ethics.",
			},
		],

		Mission: [
			{
				point:
					"Strengthening the department to produce high quality engineers.",
			},
			{
				point: "Scaling up education, research and development.",
			},
			{
				point:
					"Evincing expertise through centers of excellence in focused areas of Mechanical Engineering.",
			},
		],

		PEOs: [
			{
				point: (
					<a href={FILE_BASE_URL + "me-b.tech.pdf"} target="_blank">
						B.Tech PEOs
					</a>
				),
			},
			{ point: "M.Tech" },
		],

		Hod: {
			name: "Dr. Pugazhvadivu M",
			designation: "Professor and Head",
			img: Image,
		},
	},
	departmentalLinks: [
		{
			text: "Departmental Committies",
			link: FILE_BASE_URL + "departmental-links/me-committees.pdf",
		},
		{
			text: "Notable Alumni",
			link: FILE_BASE_URL + "departmental-links/me-alumni.pdf",
		},
		{
			text: "Placement Details",
			link: FILE_BASE_URL + "departmental-links/me-placement.pdf",
		},
		{
			text: "Medals & Awards",
			link: FILE_BASE_URL + "departmental-links/me-medals-and-awards.pdf",
		},
		{
			text: "Retired Faculty",
			link: FILE_BASE_URL + "departmental-links/me-retired-faculty.pdf",
		},
		// {
		// 	text: "Students",
		// 	link: "",
		// },
		{
			text: "Events (Past & Upcoming)",
			link: FILE_BASE_URL + "departmental-links/me-events.pdf",
		},
	],
	Research: {
		research1: {
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		research2: {
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		research3: {
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		research4: {
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		research5: {
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		research6: {
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
	},
	curriculum: [
		{
			name: "B.Tech MECHANICAL",
			desc: "A Bachelor of Technology is an undergraduate academic degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2020-21",
					link: SYLLABUS_BASE_URL + "BT_ME_2021.pdf",
				},
			],
		},
		{
			name: "B.Tech MECHATRONICS",
			desc: "A degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2020-21",
					link: SYLLABUS_BASE_URL + "BT_MT_2021.pdf",
				},
			],
		},
		{
			name: "M.Tech Energy Technology",
			desc: "A degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2020-21",
					link: SYLLABUS_BASE_URL + "M.Tech_Energy_Technology.pdf",
				},
			],
		},
		{
			name: "M.Tech PDM",
			desc: "A degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2020-21",
					link: SYLLABUS_BASE_URL + "M.Tech_PDM.pdf",
				},
			],
		},
	],
	facilities: [],
};
