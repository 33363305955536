import React from 'react'
import "./style.css"
import { Data } from "./data.js"
import img1 from "../../assets/images/WL2.jpg"
import img2 from "../../assets/images/SH1.jpg"

function Overview() {
  const value = Data.overview
  return (
    <div className='p-9 lg:text-6xs my-[2rem] lg:mx-[15rem] md:mx-[5rem] sm:mx-[2rem] academics-overview-container'>
        <h1 className='m-0 pb-6 text-red-800 font-bold'>
           Brief Overview on Academics:
        </h1>
        <div className='pb-6 text-blue-900'>
          <div className='block py-3'>
            <img src={img2} alt="" className='academics-overview-image' />
            <p className='py-7'>
             {value[0]}
            </p>
          </div>
          <div className='block py-3'>
            <img src={img1} alt="" className='academics-overview-image' />
            <p className='py-7'>
             {value[1]}
            </p>
          </div>
        </div>
    </div>
  )
}

export default Overview