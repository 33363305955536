import React from 'react'
import { data } from "./DesignClub"

function Updates() {
  return (
    <div className=' p-3'>
      <h1 className='bg-red-800 py-3 px-6 text-white text-2xl font-semibold m-0'>Updates</h1>
      <div className='p-8 bg-red-50'>
        {
          data.updates.map((e)=>{
            return (
            <div className='my-8'>
              <h2 className='font-semibold text-black text-xl'>{e.topic}</h2>
              <p className='mb-6'>{e.content}</p>
              <hr className='bg-red-800' />
           </div>
            )
          })
        }
      <button className='bg-blue-700 text-white px-4 py-2 mt-4'>View More</button>
      </div>
    </div>
  )
}

export default Updates