import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setModalVisibility } from "../../store/slices/modals";

import "./VisionAndMission.css";

export function ListSection({ type = "normal", className, title, list }) {
	return (
		<section className={`list-section list-section--${type} ${className}`}>
			<h3 className="list-section__title">{title}</h3>
			<ul className="list-section__list">
				{list.map(({ content }, index) => (
					<li className="list-section__item" key={index}>
						{content}
					</li>
				))}
			</ul>
		</section>
	);
}

const customStyles = {
	content: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "transparent",
		border: "none",
	},
	overlay: {
		zIndex: 9999,
	},
};

function VisionAndMission() {
	const dispatch = useDispatch();
	const visionVisibility = useSelector(
		(state) => state.modals.data["main-vision"]
	);
	const missionVisibility = useSelector(
		(state) => state.modals.data["main-mission"]
	);

	const handleOnClose = () => {
		dispatch(setModalVisibility({ name: "main-vision", visibility: false }));
		dispatch(setModalVisibility({ name: "main-mission", visibility: false }));
	};

	return (
		<>
			{(visionVisibility || missionVisibility) && (
				<div className="vision-and-mission-root">
					{visionVisibility && (
						<section className="vision-root">
							<ListSection
								type="outline"
								title="Vision"
								list={[
									{
										content:
											"To evolve into a university of global eminence through transformative learning and research in frontier areas of engineering and technology towards developing holistic technology leaders, innovators and entrepreneurs.",
									},
								]}
							/>
							<button
								className="vision-and-mission-close-btn"
								onClick={handleOnClose}
							>
								X
							</button>
						</section>
					)}

					{missionVisibility && (
						<section className="mission-root">
							<ListSection
								type="contained"
								title="Mission"
								list={[
									{
										content:
											"To create a conducive environment and challenging learning opportunities to shape, nurture and promote the innate scholastic capabilities of students and scholars through a socially relevant and outward-facing curriculum.",
									},
									{
										content:
											"To establish the best-of-breed Centres of Research (CoRs) and create an ecosystem for innovation and independent thinking in multidisciplinary areas of science, engineering and technology.",
									},
									{
										content:
											"To build synergistic partnerships with leading universities and technology corporates around shared agendas towards enabling a smooth transition for graduates from university to academia and industry.",
									},
									{
										content:
											"To promote entrepreneurial spirit, sustainable development outlook and build strong leadership capabilities.",
									},
								]}
							/>
							<button
								className="vision-and-mission-close-btn"
								onClick={handleOnClose}
							>
								X
							</button>
						</section>
					)}
				</div>
			)}
		</>
	);
}

export default VisionAndMission;
