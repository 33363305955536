import React from "react";
import { FaBars } from "react-icons/fa";

import "./MainNav.css";

function MobileNavBar({ onClick, ...rest }) {
	return (
		<div {...rest} className={`mobile-nav-bar ${rest.className ?? ""}`}>
			<FaBars onClick={onClick} />
		</div>
	);
}

export default MobileNavBar;
