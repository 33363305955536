import React from "react";
import Footer from "../../../components/Footer";
import { CIRCULAR_BASE_URL, getProfileImageUrl } from "../../../config";
import { DeanHeaderContainer, navItems } from "../../Dean/components/Header";

import "../Calendar/Calendar.css";
import "../FormsTemplates/FormsTemplates.css";
import FormsTemplateSection from "../FormsTemplates/FormsTemplateSection";

function Circulars() {
	return (
		<>
			<DeanHeaderContainer
				deanImg={getProfileImageUrl("hima.jpg")}
				deanName="Dr.S.Himavathi"
				deanDesignation="Dean (Academic Courses)"
				title={`ACADEMICS`}
				navItems={navItems}
			/>

			<div>
				<FormsTemplateSection
					title="Circulars"
					tableProps={{
						headers: [{ text: "S.No" }, { text: "File" }],
						rows: circularsData.map(({ text, url }, i) => ({
							title: String(i + 1),
							link: {
								text,
								href: `${CIRCULAR_BASE_URL}academics/${url}`,
							},
						})),
					}}
				/>
			</div>
			<Footer />
		</>
	);
}

const circularsData = [
	{
		text: "New Class Timings - 9 March 2022",
		url: "0_new class timings_9 March 2022.pdf",
	},
	{
		text: "OEC Final Call - 23 feb 2022",
		url: "1_OEC final call _ 23 feb 2022.pdf",
	},
	{
		text: "Academic Guidelines - 02 march 2022",
		url: "2_academic guidelines_02 march 2022.pdf",
	},
	{
		text: "Saturdays as Instructional days - 11 feb 2022",
		url: "2_Saturdays as instructional days- 11 feb 2022.pdf",
	},
	{
		text: "First Year Induction - 23 feb 2022",
		url: "3A_ first_yr_induction - 23 feb 2022.pdf",
	},
	{
		text: "First Year Induction Program",
		url: "3B_first yr induction program - 2 march 2022.pdf",
	},
	{
		text: "Even Semester Course Registration - 18 may 22",
		url: "4A_Even sem_course Registration _18 may 22.pdf",
	},
	{
		text: "Even Semester Course Registration - 4 june 2022",
		url: "4B_ even sem_course registration_4 june 2022.pdf",
	},
	{
		text: "PG Odd Semester Course Registration - 29 march 2022",
		url: "4C_PG odd sem-Course Registration-29 march 2022.pdf",
	},
	{
		text: "First Semester B.Tech - Student Enrollment - 11 april 2022",
		url: "5_First Sem B.Tech-Student Enrollment-11april 2022.pdf",
	},
	{
		text: "Offline Classes for I Aemester B.Tech - 11 feb 2022",
		url: "5_offline classes for I semester B.Tech-11 feb 2022.pdf",
	},
	{
		text: "First Semester B.Tech - Last working date extension - 2 May 2022",
		url: "5B_First Sem B.Tech- Last working date extension_2 May 2022.pdf",
	},
	{
		text: "Faculty Summer Vacation - 1 april 2022",
		url: "6_ faculty_Summer Vacation_1 april 2022.pdf",
	},
	{
		text: "B.Tech Assesment Schedule - 11 march 2022",
		url: "7_ B.Tech Assesmentschedule-11 march 2022.pdf",
	},
	{
		text: "Swayam Course Declaration - 24 May 2022",
		url: "8_Swayam course declaration_24 May 2022.pdf",
	},
	{
		text: "OEC Classroom Details - 8 march 2022",
		url: "9_OEC Classroom details_8 march 2022.pdf",
	},
];

export default Circulars;
