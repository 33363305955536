import { useEffect } from "react";

export default function usePageTitle(title) {
	useEffect(() => {
		const oldTitle = document.title;
		document.title = title;
		console.log(document.title);
		return () => {
			document.title = oldTitle;
		};
	}, [title]);
}
