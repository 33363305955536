import React from "react";
import { Link } from "react-router-dom";

import "./Notice.css";
import "../../../../components/NewsPTU/NewsPTU.css";
import { AutoScrollContainer, Updated } from "../../../../components/NewsPTU";
import useFetch from "../../../../hooks/useFetch";

export default function Notices({ value }) {
	return (
		<div className="news-corner md:grid grid-cols-6 gap-4 my-20 mx-10">
			<BulletinBoard
				visiblity="all"
				dept={value.About.ShortName.toLowerCase()}
			/>
			<div className="news-part mr-5">
				<div className="news-in-part px-10">
					<div className="flex justify-center">
						<h3 className="not-text text-center text-2xl">
							Departmental links
						</h3>
					</div>
					<br />
					<ol className="not-ol text-left">
						<br />

						{/* <Link to={`departmental-Committees`}> */}
						{/* <Link to={`alumini`}> */}
						{/* <Link to={`placement-details`}> */}
						{/* <Link to={`medals`}> */}
						{/* <Link to={`retired-faculty`}> */}
						{/* <Link to={`students`}> */}
						{/* <Link to={`upcomming-events`}> */}

						{value?.departmentalLinks.map(({ text, link }) => (
							<li className="not-li my-5">
								<a href={link} target="_blank" rel="noreferrer">
									{text}
								</a>
							</li>
						))}
					</ol>
				</div>
			</div>
		</div>
	);
}

export function BulletinBoard({ dept = "cse" }) {
	const response = useFetch(
		"News_Event.php?visiblity=" + dept + "&content=notices"
	);

	const { data, error } = response;

	if (error) {
		return (
			<div className="news-ptu-part">
				<div className="news-ptu-part__head">
					<h2>Bulletin board</h2>
				</div>

				<div className="updates-container">
					<h2>Something went wrong</h2>
				</div>
			</div>
		);
	}

	if (!data || data.length === 0) {
		return (
			<div className="news-ptu-part">
				<div className="news-ptu-part__head">
					<h2>Bulletin board</h2>
				</div>

				<div className="updates-container flex justify-center items-center">
					<h2>{!data ? "Loading" : "No Notices and Updates"}</h2>
				</div>
			</div>
		);
	}

	return (
		<AutoScrollContainer title="Notices and Updates" data={data}>
			{(e, i) => {
				return (
					<Updated
						key={i}
						topic={e.title}
						byWhom={e.given_by}
						message={e.news_content}
						file_name={e.file_name}
					/>
				);
			}}
		</AutoScrollContainer>
	);
}
