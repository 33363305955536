import React from 'react'
import { data } from "./DesignClub"
function About() {
  return (
    <div className='col-span-5'>
       <div className='flex'>
         <div className='flex items-center'>
           <h1 className='font-semibold px-7 text-red-800 border-b-4 border-red-500'>{""}</h1>
         </div>
         <h1 className='font-semibold px-4 text-red-800'>About</h1>
       </div>
       <div className='lg:pr-8 pb-6 text-justify'>{data.about}</div>
    </div>
  )
}

export default About