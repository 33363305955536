import React from "react";
import Footer from "../../../components/Footer";
import { CIRCULAR_BASE_URL, getProfileImageUrl } from "../../../config";
import { DeanHeaderContainer, navItems } from "../../Dean/components/Header";

import "../Calendar/Calendar.css";
import "../FormsTemplates/FormsTemplates.css";
import FormsTemplateSection from "../FormsTemplates/FormsTemplateSection";

function StudentInductionProgram() {
	return (
		<>
			<DeanHeaderContainer
				deanImg={getProfileImageUrl("hima.jpg")}
				deanName="Dr.S.Himavathi"
				deanDesignation="Dean (Academic Courses)"
				title={`ACADEMICS`}
				navItems={navItems}
			/>
			<div>
				<FormsTemplateSection
					title="Student Induction Programme"
					tableProps={{
						headers: [{ text: "S.No" }, { text: "File" }],
						rows: [
							{
								title: "1",
								link: {
									href:
										CIRCULAR_BASE_URL +
										"induction-program/first_yr_induction circular - 23 feb 2022.pdf",
									text: "Induction Circular 1",
								},
							},
							{
								title: "2",
								link: {
									href:
										CIRCULAR_BASE_URL +
										"induction-program/first yr circular- 2 march 2022.pdf",
									text: "Induction Circular 2",
								},
							},
							{
								title: "3",
								link: {
									href:
										CIRCULAR_BASE_URL +
										"induction-program/PTU - Induction 2021-22.pdf",
									text: "Induction Programme Report",
								},
							},
						],
					}}
				/>
			</div>
			<Footer />
		</>
	);
}

export default StudentInductionProgram;
