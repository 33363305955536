import React from "react";

import { BiSearchAlt } from "react-icons/bi";
import { FaBars } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import HodCard from "../hod";
import { getImageUrl } from "../../../../config";

const deptNavLink = [
	{
		to: "/about",
		text: "DEPARTMENT",
	},
	{
		to: "/faculty",
		text: "FACULTY",
	},
	{
		to: "/syllabus",
		text: "CURRICULUM",
	},
	{
		to: "/research",
		text: "RESEARCH",
	},
	{
		to: "/facilities",
		text: "FACILITIES",
	},
];

export default function DeptHeader({ value }) {
	const location = useLocation();

	let toggle_nav = () => {
		document
			.getElementsByClassName("small-navbar")[0]
			.classList.toggle("hidden");
	};

	let go_top = () => {
		return window.scroll(0, 0);
	};

	const navLinks = value.navLinks ?? deptNavLink;

	return (
		<>
			<div className="deptHead">
				<div className="header_dept flex justify-around">
					<div className="title_dept flex items-center">
						<Link
							to="/"
							style={{ padding: 0, margin: 0, textDecoration: "none" }}
						>
							<img
								src={getImageUrl("PTU_Logo-color.png")}
								alt="aaaaaaaaaaaaa"
								className="img-top"
							/>
						</Link>
						<h3>{value.About.LongName}</h3>
					</div>
					<div className="searchbar_dept">
						<input type="text" placeholder="Search here" />
						<BiSearchAlt className="search_icon" />
					</div>
				</div>
				<div className="imgsec">
					<img src={value.About.DeptImg} alt="" />
				</div>

				{(location.pathname === value.basePath ||
					location.pathname === value.basePath + "/" ||
					location.pathname === value.basePath + "/about" ||
					location.pathname === value.basePath + "/about/") && (
					<HodCard value={value} />
				)}
			</div>

			<div className="navbar_for_dept justify-right lg:justify-center">
				<div
					className="bars lg:hidden block"
					onClick={() => {
						toggle_nav();
					}}
				>
					<FaBars />
				</div>
				<div className="small-navbar hidden lg:hidden block">
					{navLinks.map(({ href, to, text }) => {
						if (to) {
							return (
								<Link
									to={value.basePath + to}
									onClick={() => {
										toggle_nav();
										go_top();
									}}
								>
									<p>{text}</p>
								</Link>
							);
						}

						return (
							<a href={href} target="_blank" rel="noreferrer">
								<p>{text}</p>
							</a>
						);
					})}
				</div>
				<div className="nav-inside lg:flex lg:justify-center hidden">
					{navLinks.map(({ href, to, text }) => {
						if (to) {
							return (
								<Link
									to={value.basePath + to}
									onClick={() => {
										go_top();
									}}
								>
									<p>{text}</p>
								</Link>
							);
						}

						return (
							<a href={href} target="_blank" rel="noreferrer">
								<p>{text}</p>
							</a>
						);
					})}
				</div>
			</div>
		</>
	);
}
