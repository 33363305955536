import { FILE_BASE_URL, SYLLABUS_BASE_URL } from "../../../config";

import Image from "../../../../src/components/assets/ECE_HOD_NEW.jpg";

import Dimg from "../../../../src/components/assets/ecedeptnew.jpg";

export const ECE = {
	basePath: "/ece",

	navLinks: [
		{
			to: "/about",
			text: "DEPARTMENT",
		},
		{
			to: "/faculty",
			text: "FACULTY",
		},
		{
			to: "/syllabus",
			text: "CURRICULUM",
		},
		{
			href: FILE_BASE_URL + "ece-research.html",
			text: "RESEARCH",
		},
		{
			to: "/facilities",
			text: "FACILITIES",
		},
	],

	About: {
		DeptName: "Electronics and Communication engineering",
		ShortName: "ECE",
		LongName: "DEPARTMENT OF Electronics and Communication engineering",

		DeptImg: Dimg,

		count: [
			{
				name: "B.Tech Students",
				count: 344,
			},
			{
				name: "M.Tech Students(ECE)",
				count: 14,
			},
			{
				name: "M.Tech Students(WC)",
				count: 3,
			},
			{
				name: "Research Scholars",
				count: 66,
			},
		],

		about: [
			`The Dept. of Electronics and Communication Engineering, Pondicherry Engineering College was established during the inception of the institute, in 1985. It has a strong undergraduate programme in Electronics and Communication Engineering B.Tech (ECE) along with M.Tech in Electronics and Communication Engineering and M.Tech Wireless Communication and Ph.D degrees.`,
			`The principal mission of the department is to formulate a very strong foundation in the various aspects of Electronics and Communication and provide training in the contemporary areas such as VLSI, Embedded system, Wireless communication, IOT etc.`,
		],

		vision: [
			{
				point:
					"To produce globally competitive and socially sensitized engineering graduates and to bring out quality research in the frontier areas of Electronics and Communication Engineering.",
			},
		],

		Mission: [
			{
				point:
					"To provide quality and contemporary education in the domain of Electronics and Communication Engineering through periodically updated curriculum, best-of-breed laboratory facilities, collaborative ventures with industries and effective teaching-learning process.",
			},
		],

		PEOs: [
			{
				point: (
					<a href={FILE_BASE_URL + "ece-b.tech.pdf"} target="_blank">
						B.Tech PEOs
					</a>
				),
			},
			{ point: "M.Tech" },
		],

		Hod: {
			name: "Dr. V. Saminadan",
			designation: "Professor and Head",
			phnNo: "9486622980",
			email: "hod.ece@ptuniv.edu.in",
			img: Image,
			content: `Welcome to the Dept. of Electronics and Communication Engineering! It gives me great pleasure to introduce the illustrious Dept. of Electronics and Communication Engineering. 
			The Dept. of Electronics and Communication Engineering, Pondicherry Engineering College was established during the inception of the institute, in 1985.  It has a strong undergraduate programme in Electronics and Communication Engineering B.Tech (ECE) along with M.Tech in Electronics and Communication Engineering and M.Tech Wireless Communication and Ph.D degrees. The principal mission of the department is to formulate a very strong foundation in the various aspects of Electronics and Communication and provide training in the contemporary areas such as VLSI, Embedded system, Wireless communication, IOT etc. 
			The Department has a dedicated team of faculty members with 12 Professors, 2 Associate Professors and 3 Assistant Professors.  I am proud to mention that all faculty of this eminent department are Doctorates and have specialized in key research areas such as Signal Processing, Image processing, Wireless Communication, Optical Networks, Biomedical Engineering, Information security and VLSI.  The faculty members enable the students to obtain strong knowledge of fundamentals and give them excellent analytical training.  The department is ably supported by technical staff consisting of Senior instructors, Mechanics and Lab attendants who assist the faculty and students.    
			Keeping in mind, the tremendous and fast growth in the areas of Wireless communication and VLSI, curriculum revision is carried out every 4-5 years and the last curriculum updation was done in 2018.  An innovative syllabus based on AICTE model curriculum has been framed. The students are given opportunity to obtain a Honours degree or Minor degree in their chosen specialization area. The students also have the opportunity to go for internship in their last semester to firms of their choice and get a hands-on experience with the industry trends and get placement in these firms. 
			The department has been able to receive funds from external agencies such as TEQIP, AICTE and DST to setup state-of-the-art laboratories ranging from standard test equipment  for the basic laboratories to the  high-end equipment such as Spectrum analysers, Vector network analysers,  Microwave test benches, National Instruments Labview WSN platform, Network Simulator software,  Fibre optic experimentation kits, DSP and Embedded system design kits and VLSI design software  In addition, Industry collaborative laboratories, namely,  the Freescale Semoconductors' Embedded systems laboratory established as an MOU with Freescale Semiconductors, E-yantra robotics Lab from IIT Mumbai, Texas Instruments Innovation lab, Capegemini’s Computer Networking Lab  are established in the department to provide the latest technologies to the students.   The Department also has adequate Computing facilities for UG and PG students. Hence, students of this department have the unique opportunity to augment all theoretical concepts with laboratory training. 
			Two annual events are distinctive to the Dept. of Electronics and Communication Engineering.  One is the VSNL sponsored panel discussion conducted every year centered on topics of recent interest. Panelists from industry and academia are invited and share their knowledge on the latest advancements in their field.     Another exclusive event, special only to the Dept. of Electronics and Communication Engineering is the Above and Beyond Awards, sponsored by renowned alumni of the Electronics and Communication Engineering Dept., Mr. V.Aroumugam and Mr. V.Muthukumaravelou. Every year this award is distributed to students achieving excellence in academic performance as well as innovative design project. The award involves a citation as well as cash awards.
			Students of the department are also encouraged to organize workshops, and technical symposiums such as Genesis and EnC Info, to impart in them organizational and management skills which is very important for their overall development.  In addition, students are encouraged to participate in technical and nontechnical events organized in other institutes to get exposure in interdisciplinary fields. The Dept also brings out ECE Tabloid, a department newsletter every semester in which the department activities are published.  
			The Department has continuously progressed over the years and has been bringing out quality B.Tech and M.Tech engineers who are sought after by leading industrial organizations all over the world. Consistently through these years, students of Electronics and Communication Engineering have obtained good placement in campus interviews.  In addition, students have also undertaken higher studies in India and abroad and the Electronics and Communication Engineering department is proud of its alumni who occupy key positions in global organizations such as Microsoft, Apple, Qualcomm, Bosch, HP, Intel, Siemens, to name a few.  Students of the Department have also chosen their career in Civil services and are IAS, IRS and IPS officers. The Department has an excellent track record and has been growing in strength with strong alumni base and fresh student talents over the years. 
			With this short introduction, I welcome you to be a part of our journey to achieve the vision of excellence in teaching, training and research. 
			`,
		},
	},
	departmentalLinks: [
		{
			text: "Departmental Committies",
			link: FILE_BASE_URL + "departmental-links/ece-committees.pdf",
		},
		{
			text: "Notable Alumni",
			link: FILE_BASE_URL + "departmental-links/ece-alumni.pdf",
		},
		{
			text: "Placement Details",
			link: FILE_BASE_URL + "departmental-links/ece-placement.pdf",
		},
		{
			text: "Medals & Awards",
			link: FILE_BASE_URL + "departmental-links/ece-medals-and-awards.pdf",
		},
		{
			text: "Retired Faculty",
			link: FILE_BASE_URL + "departmental-links/ece-retired-faculty.pdf",
		},
		// {
		// 	text: "Students",
		// 	link: "",
		// },
		{
			text: "Events (Past & Upcoming)",
			link: FILE_BASE_URL + "departmental-links/ece-events.pdf",
		},
		{
			text: "Tabloid",
			link: FILE_BASE_URL + "EceTabloid.html",
		},
	],
	Research: [
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
	],
	curriculum: [
		{
			name: "B.Tech",
			desc: "A Bachelor of Technology is an undergraduate academic degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2018-19",
					link: SYLLABUS_BASE_URL + "3_BT_EC_1819.pdf",
				},
			],
		},
		{
			name: "M.Tech. (Electronics and Communication Engineering)",
			desc: "A degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2018-19",
					link: SYLLABUS_BASE_URL + "MT_EC_EC_290121.pdf",
				},
			],
		},
		{
			name: "M.Tech. (Wireless Communication)",
			desc: "A degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2018-19",
					link: SYLLABUS_BASE_URL + "MT_EC_WC-290121.pdf",
				},
			],
		},
	],
	facilities: [
		{
			name: "COMMUNICATION LAB-III",
			img: "",
			content:
				"The objective of this lab is to familiarise the students with microwave and optical communication techniques/technologies in microwave communication. The students study the operation and characteristics of microwave sources such as klystron and gunn diodes, load impedance and standing wave measurements, antenna gain and radiation pattern measurements. Optical communication deals with the study of the characteristics of the optical fibre and also analog and digital fibre links using LED and LASER sources.",
		},
		{
			name: "ADVANCED COMMUNICATION LAB",
			img: "",
			content:
				"This lab is an advancement of the communication lab-III where the students perform experiments using advanced equipments such as the vector network analyser, spectrum analyser, DTA and RCA.",
		},
		{
			name: "PG COMMUNICATION DESIGN AND SIMULATION LAB",
			img: "",
			content:
				"This lab course for M.Tech students teaches the students on microwave sources and devices, optical fibre and characteristic and design of microwave and optical fibre communication links. Students are also trained to handle and perform experiments using advanced equipment available in the lab.",
		},
		{
			name: "High Performance Communication Lab",
			img: "",
			content:
				" Facility created for design and analysis of wireless communication systems using advanced testing equipment’s like Spectrum Analyser, CDMA Trainer, High frequency DSO and OFDM trainer kit and Special simulation tools NetSim and QUALNET(Research)",
		},
		{
			name: "Freescale Systems Lab",
			img: "",
			content:
				"This lab has been established as a collaborative venture between Pondicherry Engineering College and M/S Freescale Semiconductors. India Pvt. Ltd. in accordance with the MoU signed during September, 2008. Freescale Semiconductors has donated ninety development tools which include Hardware and Software.",
		},
		{
			name: "COMMUNICATION LABORATORY-I",
			img: "",
			content:
				"Students construct and analyse circuits on analog communication transmitter and receiver. The students conduct experiment to understand the signals available at different stages of AM and FM receivers.",
		},
		{
			name: "COMMUNICATION LABORATORY-II",
			img: "",
			content:
				"The objective of this course is to familiarise the students with different blocks in digital communication different digital communication. Different digital modems and codecs are constructed and tested.",
		},
		{
			name: "Computer Networks Laboratory",
			img: "",
			content:
				"HP Core 2 Duo @2.9 GHz, 2GB RAM, 160GB HDD , Windows 7/ Windows 8. 100Mbps Internet facility. 10KVA UPS.",
		},
		{
			name: "Electronic Devices Laboratory",
			img: "",
			content:
				"The primary objective of the lab is to serve as a platform for understanding the operating principles of electron devices through experiments and also to study their characteristics.",
		},
		{
			name: "DIGITAL CIRCUITS DESIGN LAB",
			img: "",
			content:
				"The lab is intented for sophomores for practising their theoretical knowledge by designing. Constucting and testing the combinational as well as sequential digital circuits.",
		},
		{
			name: "ELECTRONICS CIRCUITS LAB-I",
			img: "",
			content:
				"The students are offered hands-on experience in designing, constructing and testing the analog electronics circuits. The students design the circuits, solder them and then test the performance experimentally. This lab nurtures all the skills that are essential to design and test advanced electronics and communication circuits. The students also validate the experimental results using PSpice computer simulator.",
		},
		{
			name: "ELECTRONICS CIRCUITS LAB-II",
			img: "",
			content:
				"This laboratory course is an advanced extension of Electonics circuits lab-I. The students design advanced analog and pulse circuits.They construct the circuit and test them. This lab builds the confidence of the students to design the advanced electronic circuits. The students study the impact of various design parameters on the performance of the circuits using PSpice computer simulator.",
		},
		{
			name: "Wireless Communication Laboratory",
			img: "Lenovo V530 - Intel® core TM :3-8100 CPU @3.60 GHz 4 GB RAM 64-bit Operating system X64 based processor - Windows 10. HP - Intel® core TM :i5-6500CPU@3.20 GHz 4 GB RAM 64-bit Operating system X64 based processor - Windows 10. Labview 2018 and my RIO tool kit",
			content: "",
		},
	],
};
