import { FILE_BASE_URL, getImageUrl } from "../../../config";

// import Image from "../../../../src/components/assets/CSE_HOD.png"

export const HSS = {
	basePath: "/hss",

	navLinks: [
		{
			to: "/about",
			text: "DEPARTMENT",
		},
		{
			to: "/faculty",
			text: "FACULTY",
		},
		{
			to: "/syllabus",
			text: "CURRICULUM",
		},
		{
			to: "/research",
			text: "RESEARCH",
		},
		{
			to: "/facilities",
			text: "FACILITIES",
		},
	],

	About: {
		DeptName: "HUMANITIES AND SOCIAL SCIENCE",
		ShortName: "HSS",
		LongName: "DEPARTMENT OF HUMANITIES AND SOCIAL SCIENCES ",

		DeptImg: "",

		count: [],

		about: [
			"The Department of HSS was established in the year 1995 with two faculty members on its rolls. Since its inception it has grown in strength with the rise in faculty members from two to four. Currently, there are three Faculty members in English and one in Economics. The Department offers one mandatory credit course for First year B.Tech students and four Open Elective courses for III year B.Tech students. In addition, zero credit courses for UG students and MAC courses are offered for PG students. A Language Laboratory is in place for honing the students’ communication skills. The faculty Members of the Department act as Class Advisors and mentors for First year B.Tech students.",
		],
		vision: [
			{
				point:
					"To promote excellence in the teaching learning process by fostering value based learning through a humanistic approach and be a constructive stakeholder in the professional education scenario.",
			},
		],

		Mission: [
			{
				point:
					"To facilitate a stimulating environment in order to help students develop their linguistic, organizational and leadership skills.",
			},
			{
				point:
					"To inculcate a spirit of enquiry and encourage independent, analytical, creative and critical thinking.",
			},
			{
				point:
					"To create competent and conscientious professionals with a strong sense of social responsibility, commitment and compassion.",
			},
		],

		PEOs: [
			{
				point: (
					<a href={FILE_BASE_URL + "hss-b.tech.pdf"} target="_blank">
						B.Tech PEOs
					</a>
				),
			},
			{ point: "M.Tech" },
		],

		Hod: {
			name: "Dr. D.Virappassamy",
			designation: "Associate Professor and Head",
			img: getImageUrl("Unknown_person.jpg"),
			// phnNo: "",
			email: "hod.hss@ptuniv.edu.in",
			content: "",
		},
	},
	departmentalLinks: [
		{
			text: "Departmental Committies",
			link: FILE_BASE_URL + "departmental-links/hss-committees.pdf",
		},
		{
			text: "Notable Alumni",
			link: FILE_BASE_URL + "departmental-links/hss-alumni.pdf",
		},
		{
			text: "Placement Details",
			link: FILE_BASE_URL + "departmental-links/hss-placement.pdf",
		},
		{
			text: "Medals & Awards",
			link: FILE_BASE_URL + "departmental-links/hss-medals-and-awards.pdf",
		},
		{
			text: "Retired Faculty",
			link: FILE_BASE_URL + "departmental-links/hss-retired-faculty.pdf",
		},
		// {
		// 	text: "Students",
		// 	link: "",
		// },
		{
			text: "Events (Past & Upcoming)",
			link: FILE_BASE_URL + "departmental-links/hss-events.pdf",
		},
	],
	Research: [
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
	],
	curriculum: [
		{
			name: "B.Tech",
			desc: "A Bachelor of Technology is an undergraduate academic degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "Curriculum ",
					link: "",
				},
			],
		},
	],
	facilities: [],
};
