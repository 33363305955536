import React from "react";
import FormsTable from "./FormsTable";

function FormsTemplateSection({ title, tableProps }) {
	return (
		<section>
			<div className="flex justify-center items-center pt-7">
				<h1 className="m-3 pt-1 px-9 bg-orange-500 text-center text-2xl"> </h1>
				<h1 className="m-1 py-3 text-center text-2xl font-bold text-red-800">
					{title}
				</h1>
			</div>
			<div className="forms-templates__container">
				<FormsTable {...tableProps} />
			</div>
		</section>
	);
}

export default FormsTemplateSection;
