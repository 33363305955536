import React, { useState, useEffect } from "react";
import { genMonthCalender } from "./utils";

import "./Calendar.css";

function CalendarBox() {
	const [currentDate] = useState(new Date());
	const [calenderDate, setCalenderDate] = useState(new Date());
	const [calenderMatrix, setCalenderMatrix] = useState([[]]);

	useEffect(() => {
		let matrix = [];

		//generating the month base on current date
		let generatedMonth = genMonthCalender(
			calenderDate.getFullYear(),
			calenderDate.getMonth()
		);

		let isCurrentMonth =
			calenderDate.getFullYear() === currentDate.getFullYear() &&
			calenderDate.getMonth() === currentDate.getMonth();

		for (let i = 1; i <= generatedMonth.length; i++) {
			matrix.push([]);

			for (let j = 0; j < 7; j++) {
				const date =
					generatedMonth[i - 1][j] === 0 ? " " : generatedMonth[i - 1][j];

				const isCurrentSelectedDate =
					isCurrentMonth && currentDate.getDate() === generatedMonth[i - 1][j];

				matrix[matrix.length - 1].push(
					<td key={j} className={`${isCurrentSelectedDate ? "cur-date" : ""}`}>
						{date}
					</td>
				);
			}
		}

		setCalenderMatrix(matrix);
	}, [currentDate, calenderDate]);

	const changeMonthBy = (months) => {
		setCalenderDate(
			new Date(calenderDate.getFullYear(), calenderDate.getMonth() + months)
		);
	};

	return (
		<div className="calender-box__container">
			<div className="calender-box__header">
				<button onClick={() => changeMonthBy(-1)}>&lt;</button>
				<span>
					{calenderDate.getFullYear() + "-" + (calenderDate.getMonth() + 1)}
				</span>
				<button onClick={() => changeMonthBy(1)}>&gt;</button>
			</div>
			<table>
				<thead>
					<tr>
						<th>Sun</th>
						<th>Mon</th>
						<th>Tus</th>
						<th>Wen</th>
						<th>Ths</th>
						<th>Fri</th>
						<th>Sat</th>
					</tr>
				</thead>
				<tbody>
					{calenderMatrix.map((row, i) => (
						<tr key={i}>{row.map((td) => td)}</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

export default CalendarBox;
