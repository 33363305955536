import React from "react";

function FormsTable({ headers, rows }) {
	return (
		<table className="border-b border-gray-200">
			<thead className="bg-gray-50">
				<tr>
					{headers.map(({ text }, index) => (
						<th key={index} className="px-6 py-2 text-xs">
							{text}
						</th>
					))}
				</tr>
			</thead>
			<tbody className="bg-white">
				{rows.map(({ title, link }, index) => (
					<tr key={index}>
						<td className="px-6 py-4 text-sm">{title}</td>
						<td className="px-6 py-4 text-sm">
							<a
								href={link.href}
								className="underline text-blue-700 decoration-current"
								target="_blank"
								rel="noreferrer"
							>
								{link.text}
							</a>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}

export default FormsTable;
