export const data = {
    about : "The ability to design is fundamental in any form of engineering and engineering students must learn to integrate theory, professional practice and social constructs in their careers. As future engineers we have a responsibility in the systems we design; it affect the public directly or indirectly and hence, we must exercise the utmost conscientiousness in our design and implementation whilst considering professionalism and ethics as critical elements. The Design we are referring to as in ‘Design Club’ brings to the table design thinking which tightly weaves the disciplines stated above to conclude with the masterstroke of a winning solution. But again, Design in the sturdy sense of word certainly adds value but without a precise meaning. So, to conclude with a generally accepted notion, we need different types of people with different mindsets and skillsets, with regards for the aesthetics and accessibility of a design to come together, discuss, get/give feedback and come up with the solution. The club helps students of the college in taking their ideas (projects, app, etc.) to the next level. The members can also be approached to reinvent, recode and bring back to life college assets in need of renovation like the college website.  In April, 2019, the club was started by the by",
    vision : [
        "To foster collaboration amongst members and partners in developing design language solutions digitally or non-digitally and contribute pro bono towards projecting the institute as a standard setting establishment.",
    ],
    mission : [
        "To craft based on design thinking and bring into play ideas which not only are visually aesthetic and attentive to details but also warrant a positive impact.Í",
        "To be incorporated as a lighthouse-project which effectuates a knowledge and skill network facilitating learning and creativity within the campus.",
    ],
    milestones : [
       "Designing Logo for PTUÍ",
       " Designing Website for PTÌ",
    ],
    projects : [
        {name: "Members", count: 35},
        {name: "Event Designs", count: 50},
        {name: "Events Conducted", count: 10},
        {name: "M.tech Students", count: 20}
    ],
    updates : [
        {
          topic : "Get Involved In Design",
          content : "This is an open invitation to all the students of PTU to become a part of the club",  
        },
        {
          topic : "Competitions",
          content : "DC is planning to conduct various events for the AY 2021-22",  
        },
    ]
}