import React from 'react'
import "./style.css"
import TopNav from './TopNav'
import Navbar from './Navbar'
import About from './About'
import Gallery from './Gallery'
import OfficeBearers from './OfficeBearers'
import ContentBox from './ContentBox'
import Projects from './Projects'
import Updates from './Updates'
import { data } from "./DesignClub"

const DesignClub = () => {
  return (
    <div className="">
        <TopNav />
        <Navbar />
        <div className='p-8'>
            <div className='block lg:grid grid-cols-7 justify-evelnly my-5'>
              <About />
              <Gallery />
            </div>
            <OfficeBearers />
            <div className='block lg:flex justify-evelnly my-5'>
                <ContentBox name="Vision" color="no" value={data.vision}/>
                <ContentBox name="Mission" color="yes" value={data.mission} />
            </div>
            <div className='block lg:grid grid-cols-7 justify-evelnly my-5'>
                <ContentBox name="Milstones" color="yes" value={data.milestones} />
                <Projects />
            </div>
            <Updates />
        </div>
    </div>
  )
}

export default DesignClub