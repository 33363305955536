import React, { useState } from "react";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";

import { FILE_BASE_URL } from "../../config";

export const B_TECH_CURRICULUM_SYLLABUS_URL =
	FILE_BASE_URL + "academic-curriculum/b-tech/";
export const M_TECH_CURRICULUM_SYLLABUS_URL =
	FILE_BASE_URL + "academic-curriculum/m-tech/";
export const MSC_CURRICULUM_SYLLABUS_URL =
	FILE_BASE_URL + "academic-curriculum/msc/";
export const MCA_CURRICULUM_SYLLABUS_URL =
	FILE_BASE_URL + "academic-curriculum/mca/";
export const MBA_CURRICULUM_SYLLABUS_URL =
	FILE_BASE_URL + "academic-curriculum/mba/";

function Details({ summary, children }) {
	return (
		<details className="p-3 mb-2 shadow-md bg-white hover:bg-gray-100">
			<summary className="select-none">{summary}</summary>
			{children}
		</details>
	);
}

const CurriculumSyllabus = ({
	title = "ACADEMIC CURRICULUM & SYLLABUS",
	syllabus = CurriculumSyllabusData,
	hidePg,
	hideUg,
}) => {
	const [switchUgPrograms, setSwitchUgPrograms] = useState(true);
	const [switchPgPrograms, setSwitchPgPrograms] = useState(true);

	return (
		<div className="m-5 p-5">
			<div className="flex justify-center items-center pt-7">
				<h1 className="m-3 pt-1 px-9 bg-orange-500 text-center text-2xl"> </h1>
				<h1 className="m-1 py-3 text-center text-2xl font-bold text-red-800">
					{title}
				</h1>
			</div>
			<div className="py-9 lg:flex flex-wrap">
				{!hideUg && (
					<div className="flex-1 mx-2">
						<div className="flex justify-between items-center bg-gray-200 p-4 mt-6 text-blue-900">
							<div className="text-2xl">
								U<span className="hidden md:inline">nder</span>g
								<span className="hidden md:inline">raduate</span> Programme
							</div>
							<div
								onClick={() => {
									setSwitchUgPrograms(!switchUgPrograms);
								}}
								className="bg-gray-600 text-white p-3 shadow-xl hover:shadow-none font-bold"
							>
								{switchUgPrograms ? <FaAngleUp /> : <FaAngleDown />}
							</div>
						</div>
						<div
							className={`${
								switchUgPrograms === true ? "block" : "hidden"
							} ${"p-3 bg-gray-100"}`}
						>
							<ol>
								{syllabus.map(({ departmentName, syllabus }, i) => {
									if (!syllabus.ug) return null;

									return (
										<li key={`${i}`}>
											<details className="p-3 mb-2 shadow-md bg-white hover:bg-gray-100">
												<summary className="select-none">
													{departmentName}
												</summary>
												{syllabus.ug.map(({ text, ...rest }, i) => (
													<a
														key={i}
														className="block p-3 hover:text-red-600 shadow-sm hover:bg-white"
														target="_blank"
														{...rest}
													>
														{text}
													</a>
												))}
											</details>
										</li>
									);
								})}
							</ol>
						</div>
					</div>
				)}
				{!hidePg && (
					<div className="flex-1 mx-2">
						<div className="flex justify-between items-center bg-gray-200 p-4 mt-6 text-blue-900">
							<div className="text-2xl">
								P<span className="hidden md:inline">ost</span>g
								<span className="hidden md:inline">raduate</span> Programme
							</div>
							<div
								onClick={() => {
									setSwitchPgPrograms(!switchPgPrograms);
								}}
								className="bg-gray-600 text-white p-3 shadow-xl hover:shadow-none font-bold"
							>
								{switchPgPrograms ? <FaAngleUp /> : <FaAngleDown />}
							</div>
						</div>
						<div
							className={`${
								switchPgPrograms === true ? "block" : "hidden"
							} ${"p-3 bg-gray-100"}`}
						>
							<ol>
								{syllabus.map(({ departmentName, syllabus }, i) => {
									if (!syllabus.pg) return null;

									return (
										<li key={`${i}`}>
											<Details summary={departmentName}>
												{syllabus.pg.map(({ item, text, ...rest }, i) => {
													if (typeof text === "string") {
														return (
															<a
																key={i}
																className="block p-3 hover:text-red-600 shadow-sm hover:bg-white"
																target="_blank"
																{...rest}
															>
																{text}
															</a>
														);
													}

													return (
														<Details key={i} summary={item.year}>
															{item.cources.map(({ text, ...rest }, i) => (
																<a
																	key={i}
																	className="block p-3 hover:text-red-600 shadow-sm hover:bg-white"
																	target="_blank"
																	{...rest}
																>
																	{text}
																</a>
															))}
														</Details>
													);
												})}
											</Details>
										</li>
									);
								})}
							</ol>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export const CurriculumSyllabusData = [
	{
		departmentName: "Chemical Engineering",
		syllabus: {
			ug: [
				{
					text: "AY 2014-15",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "7_BT_CH_1415.pdf",
				},
				{
					text: "AY 2018-19",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "7_BT_CH_1819.pdf",
				},
				{
					text: "AY 2020-21",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "7_BT_CH_2021.pdf",
				},
			],
		},
	},
	{
		departmentName: "Information Techonology",
		syllabus: {
			ug: [
				{
					text: "AY 2014-15",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "8_BT_IT_1415.pdf",
				},
				{
					text: "AY 2018-19",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "8_BT_IT_1819.pdf",
				},
				{
					text: "AY 2020-21",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "8_BT_IT_2021.pdf",
				},
			],
			pg: [
				{
					item: {
						year: "AY 2015-16",
						cources: [
							{
								text: "M.Tech - IoT",
								href: M_TECH_CURRICULUM_SYLLABUS_URL + "19_MT_IT_IT_1516.pdf",
							},
						],
					},
				},
				{
					item: {
						year: "AY 2020-21",
						cources: [
							{
								text: "M.Tech - IoT",
								href: M_TECH_CURRICULUM_SYLLABUS_URL + "19_MT_IT_IT_2021.pdf",
							},
						],
					},
				},
			],
		},
	},
	{
		departmentName: "Computer Science and Engineering",
		syllabus: {
			ug: [
				{
					text: "AY 2014-15",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "4_BT_CS_1415.pdf",
				},
				{
					text: "AY 2018-19",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "4_BT_CS_1819.pdf",
				},
				{
					text: "AY 2020-21",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "4_BT_CS_2021.pdf",
				},
			],
			pg: [
				{
					item: {
						year: "AY 2015-16",
						cources: [
							{
								text: "MCA",
								href: MCA_CURRICULUM_SYLLABUS_URL + "20_MC_1516.pdf",
							},
							{
								text: "M.Tech - Data Science",
								href: M_TECH_CURRICULUM_SYLLABUS_URL + "15_MT_CS_DS_1516.pdf",
							},
							{
								text: "M.Tech - Information Security",
								href: M_TECH_CURRICULUM_SYLLABUS_URL + "16_MT_CS_IS_1516.pdf",
							},
						],
					},
				},
				{
					item: {
						year: "AY 2020-21",
						cources: [
							{
								text: "MCA",
								href: MCA_CURRICULUM_SYLLABUS_URL + "20_MC_2021.pdf",
							},
							{
								text: "M.Tech - Data Science",
								href: M_TECH_CURRICULUM_SYLLABUS_URL + "15_MT_CS_DS_2021.pdf",
							},
							{
								text: "M.Tech - Information Security",
								href: M_TECH_CURRICULUM_SYLLABUS_URL + "16_MT_CS_IS_2021.pdf",
							},
						],
					},
				},
			],
		},
	},
	{
		departmentName: "Civil Engineering",
		syllabus: {
			ug: [
				{
					text: "AY 2014-15",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "1_BT_CE_1415.pdf",
				},
				{
					text: "AY 2018-19",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "1_BT_CE_1819.pdf",
				},
				{
					text: "AY 2020-21",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "1_BT_CE_2021.pdf",
				},
			],
			pg: [
				{
					item: {
						year: "AY 2015-16",
						cources: [
							{
								text: "M.Tech - Environmental Engineering",
								href: M_TECH_CURRICULUM_SYLLABUS_URL + "9_MT_CE_EE_1516.pdf",
							},
							{
								text: "M.Tech - Structural Engineering",
								href: M_TECH_CURRICULUM_SYLLABUS_URL + "10_MT_CE_SE_1516.pdf",
							},
						],
					},
				},
				{
					item: {
						year: "AY 2020-21",
						cources: [
							{
								text: "M.Tech - Environmental Engineering",
								href: M_TECH_CURRICULUM_SYLLABUS_URL + "9_MT_CE_EE_2021.pdf",
							},
							{
								text: "M.Tech - Structural Engineering",
								href: M_TECH_CURRICULUM_SYLLABUS_URL + "10_MT_CE_SE_2021.pdf",
							},
						],
					},
				},
			],
		},
	},
	{
		departmentName: "Mechanical Engineering",
		syllabus: {
			ug: [
				{
					text: "AY 2014-15",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "2_BT_ME_1415.pdf",
				},
				{
					text: "AY 2018-19",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "2_BT_ME_1819.pdf",
				},
				{
					text: "AY 2020-21",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "2_BT_ME_2021.pdf",
				},
			],
			pg: [
				{
					item: {
						year: "AY 2015-16",
						cources: [
							{
								text: "M.Tech - Energy Technology",
								href: M_TECH_CURRICULUM_SYLLABUS_URL + "11_MT_ME_ET_1516.pdf",
							},
							{
								text: "M.Tech - Product Design and Manufacturing",
								href: M_TECH_CURRICULUM_SYLLABUS_URL + "12_MT_ME_PD_1516.pdf",
							},
						],
					},
				},
				{
					item: {
						year: "AY 2020-21",
						cources: [
							{
								text: "M.Tech - Energy Technology",
								href: M_TECH_CURRICULUM_SYLLABUS_URL + "11_MT_ME_ET_2021.pdf",
							},
							{
								text: "M.Tech - Product Design and Manufacturing",
								href: M_TECH_CURRICULUM_SYLLABUS_URL + "12_MT_ME_PD_2021.pdf",
							},
						],
					},
				},
			],
		},
	},
	{
		departmentName: "Mechatronics Engineering",
		syllabus: {
			ug: [
				{
					text: "AY 2020-21",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "2A_BT_MT_2021.pdf",
				},
			],
		},
	},
	{
		departmentName: "Electrical and Electronics Engineering",
		syllabus: {
			ug: [
				{
					text: "AY 2014-15",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "5_BT_EE_1415.pdf",
				},
				{
					text: "AY 2018-19",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "5_BT_EE_1819.pdf",
				},
				{
					text: "AY 2020-21",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "5_BT_EE_2021.pdf",
				},
			],
			pg: [
				{
					item: {
						year: "AY 2015-16",
						cources: [
							{
								text: "M.Tech - Electrical Drives and Control",
								href: M_TECH_CURRICULUM_SYLLABUS_URL + "17_MT_EE_ED_1516.pdf",
							},
						],
					},
					item: {
						year: "AY 2020-21",
						cources: [
							{
								text: "M.Tech - Electrical Drives and Control",
								href: M_TECH_CURRICULUM_SYLLABUS_URL + "17_MT_EE_ED_2021.pdf",
							},
						],
					},
				},
			],
		},
	},
	{
		departmentName: "Electronics and Communication Engineering",
		syllabus: {
			ug: [
				{
					text: "AY 2014-15",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "3_BT_EC_1415.pdf",
				},
				{
					text: "AY 2018-19",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "3_BT_EC_1819.pdf",
				},
				{
					text: "AY 2020-21",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "3_BT_EC_2021.pdf",
				},
			],
			pg: [
				{
					item: {
						year: "AY 2015-16",
						cources: [
							{
								text: "M.Tech - Electronics and Communication Engineering",
								href: M_TECH_CURRICULUM_SYLLABUS_URL + "13_MT_EC_EC_1516.pdf",
							},
							{
								text: "M.Tech - Wireless Communication",
								href: M_TECH_CURRICULUM_SYLLABUS_URL + "14_MT_EC_WC_1516.pdf",
							},
						],
					},
				},
				{
					item: {
						year: "AY 2020-21",
						cources: [
							{
								text: "M.Tech - Electronics and Communication Engineering",
								href: M_TECH_CURRICULUM_SYLLABUS_URL + "13_MT_EC_EC_2021.pdf",
							},
							{
								text: "M.Tech - Wireless Communication",
								href: M_TECH_CURRICULUM_SYLLABUS_URL + "14_MT_EC_WC_2021.pdf",
							},
						],
					},
				},
			],
		},
	},
	{
		departmentName: "Electronics and Instrumentation Engineering",
		syllabus: {
			ug: [
				{
					text: "AY 2014-15",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "6_BT_EI_1415.pdf",
				},
				{
					text: "AY 2018-19",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "6_BT_EI_1819.pdf",
				},
				{
					text: "AY 2020-21",
					href: B_TECH_CURRICULUM_SYLLABUS_URL + "6_BT_EI_2021.pdf",
				},
			],
			pg: [
				{
					item: {
						year: "AY 2015-16",
						cources: [
							{
								text: "M.Tech - Instrumentation Engineering",
								href: M_TECH_CURRICULUM_SYLLABUS_URL + "18_MT_EI_IE_1516.pdf",
							},
						],
					},
				},
				{
					item: {
						year: "AY 2020-21",
						cources: [
							{
								text: "M.Tech - Instrumentation Engineering",
								href: M_TECH_CURRICULUM_SYLLABUS_URL + "18_MT_EI_IE_2021.pdf",
							},
						],
					},
				},
			],
		},
	},
	{
		departmentName: "Physics",
		syllabus: {
			pg: [
				{
					item: {
						year: "AY 2019-20",
						cources: [
							{
								text: "M.Sc - Materials Science & Technology",
								href: MSC_CURRICULUM_SYLLABUS_URL + "21_MSc_1920.pdf",
							},
						],
					},
				},
			],
		},
	},
].sort((d1, d2) => d1.departmentName.localeCompare(d2.departmentName));

// To make it as first
CurriculumSyllabusData.unshift({
	departmentName: "First Year",
	syllabus: {
		ug: [
			{
				text: "AY 2020-21",
				href: MSC_CURRICULUM_SYLLABUS_URL + "0_BT_FY_2021.pdf",
			},
		],
	},
});

// To make it as last
CurriculumSyllabusData.push({
	departmentName: "Master of Business Administration",
	syllabus: {
		pg: [
			{
				item: {
					year: "AY 2020-21",
					cources: [
						{
							text: "MBA - Innovation, Entrepreneurship & Venture Development",
							href: MBA_CURRICULUM_SYLLABUS_URL + "23_PTU_MB_IEVD_2021.pdf",
						},
					],
				},
			},
			{
				item: {
					year: "AY 2021-22",
					cources: [
						{
							text: "MBA - International Business",
							href: MBA_CURRICULUM_SYLLABUS_URL + "24_PTU_MB_IB_2122.pdf",
						},
					],
				},
			},
		],
	},
});

export default CurriculumSyllabus;
