import { FILE_BASE_URL, SYLLABUS_BASE_URL } from "../../../config";

import Image from "../../../../src/components/assets/MT_Hod.png";

//import Dimg from "/../../../../src/components/assets/ecedeptnew.jpg";

export const MT = {
	basePath: "/mt",

	navLinks: [
		{
			to: "/about",
			text: "DEPARTMENT",
		},
		{
			to: "/faculty",
			text: "FACULTY",
		},
		{
			to: "/syllabus",
			text: "CURRICULUM",
		},
		{
			href: FILE_BASE_URL + "ece-research.html",
			text: "RESEARCH",
		},
		{
			to: "/facilities",
			text: "FACILITIES",
		},
	],

	About: {
		DeptName: "Mechatronics Engineering",
		ShortName: "MT",
		LongName: "DEPARTMENT OF Mechatronics Engineering",

		//DeptImg: Dimg,

		count: [
			{
				name: "B.Tech Students",
				count: 344,
			},
		],

		about: [
			`Welcome to the Department of Mechatronics at the Puducherry Technological University. Mechatronics programme is one of the youngest department in PTU, established in the year 2020, with a sanctioned intake of 60 students and offer four years’ full time B.Tech. in Mechatronics. Mechatronics programme is committed to prepare graduates to synergistically integrate mechanical engineering with electronic and intelligent computer control in the design and manufacture of industrial products and processes. The department is dedicated to prepare graduates with strong team skills to solve multi disciplinary problems using Mechatronics approach. The department encourages a majority of our graduates to pursue advanced studies in thrust areas of Mechatronics and to carry out scientific, industrial and defense research and development so as to meet/satisfy current requirements in respective sectors. The curriculum is designed to prepare our graduates to improve their self-reliant capabilities, soft skills, leadership qualities which would help in building their own careers and make them become successful entrepreneurs to serve the nation and the society responsibly and ethically. The curriculum of study is tailored to gratify to the needs of ever-changing technology and industrial environment in the context of global as well as Indian perspectives. Department is devoted to students to familiarize with international and national codes and standards for good engineering practice in core and interdisciplinary fields and to help them evolve sustainable development in technological sphere with greater emphasis on mitigation of environmental impact.`
,
		],

		vision: [
			{
				point:
					"To produce dynamic, vibrant, demand driven and quality conscious human resources in the frontier areas of Mechatronics Engineering through consistent and sustained development ensuring highest standards of technological ethics.",
			},
		],

		Mission: [
			{
				point:
					"Strengthening the department to produce high quality engineers",	
			},
			{
				point:
					"Scaling of education, research and development",	
			},
			{
				point:
					"Evincing expertise through centers of excellence in focused areas of mechatronics ",	
			},
		],

		PEOs: [
			{
				point:"The Mechatronics programme will prepare graduates to synergistically integrate mechanical engineering with electronic and intelligent computer control in the design and manufacture of industrial products and processes (Fundamentals)",
			},
			{
				point:"The programme will prepare graduates with strong team skills to solve multi-disciplinary problems using Mechatronics approach (Employability).",
			},
			{
				point:"To encourage a majority of our graduates to pursue advanced studies in thrust areas of Mechatronics and to carry out scientific, industrial and defense research and development so as to meet/satisfy current requirements in respective sectors (Higher Studies).",
			},
			{
				point:"To prepare our graduates to improve their self-reliant capabilities, soft skills, leadership qualities which would help in building their own careers and make them become successful entrepreneurs to serve the nation and the society responsibly and ethically (Entrepreneurship).",
			},
			{
				point:"To familiarize our graduates with international and national codes and standards for good engineering practice in core and interdisciplinary fields and to help them evolve sustainable development in technological sphere with greater emphasis on mitigation of environmental impact (Professional Ethics).",
			},
		],

		Hod: {
			name: "Dr. R. Elansezhian",
			designation: "Professor and Programme Coordinator",
			phnNo: "9952884403",
			email: "hodmechatronics@ptuniv.edu.in",
			img: Image,
			content: `Welcome to the Department of Mechatronics at the Puducherry Technological University. Mechatronics programme is one of the youngest department in PTU, established in the year 2020, with a sanctioned intake of 60 students and offer four years’ full time B.Tech. in Mechatronics. Mechatronics programme is committed to prepare graduates to synergistically integrate mechanical engineering with electronic and intelligent computer control in the design and manufacture of industrial products and processes. The department is dedicated to prepare graduates with strong team skills to solve multi disciplinary problems using Mechatronics approach. The department encourages a majority of our graduates to pursue advanced studies in thrust areas of Mechatronics and to carry out scientific, industrial and defense research and development so as to meet/satisfy current requirements in respective sectors. The curriculum is designed to prepare our graduates to improve their self-reliant capabilities, soft skills, leadership qualities which would help in building their own careers and make them become successful entrepreneurs to serve the nation and the society responsibly and ethically. The curriculum of study is tailored to gratify to the needs of ever-changing technology and industrial environment in the context of global as well as Indian perspectives. Department is devoted to students to familiarize with international and national codes and standards for good engineering practice in core and interdisciplinary fields and to help them evolve sustainable development in technological sphere with greater emphasis on mitigation of environmental impact.

			The department offers honors degree and provides opportunity to students to do minor degree programmes in other branches of engineering. The department is involved in the process of developing its own infrastructure and having well qualified, dedicated and experienced faculty members with proficiency in teaching and research background. Art of state laboratories with latest cutting edge technology laboratories are under establishment in order to impart high quality technical training to our students. The laboratories facilitate students to acquire sound knowledge of theoretical and practical skills & engineering concepts. Mechatronics department is committed to provide guidance and counseling to its students on career and job prospects with the help of class mentors and faculty advisors, specialized short courses, student training and placement, student projects and consultancy, industry sponsored internships and guest lecturers. Mechatronics graduates are initiated to work on innovative ideas that will eventually motivate them to pursue higher studies and research in the areas of mechatronics and allied engineering/management division. Department is planning to introduce online student support mechanism to cater the needs of each and every mechatronics student. Mechatronics department looks forward to contribute in solving the technological challenges of the society with active participation from its students. Students are eligible to attend the core and IT companies during the campus recruitment process. 
			`,
		},
	},
	departmentalLinks: [
		{
			text: "Departmental Committies",
			link: FILE_BASE_URL + "departmental-links/ece-committees.pdf",
		},
		{
			text: "Notable Alumni",
			link: FILE_BASE_URL + "departmental-links/ece-alumni.pdf",
		},
		{
			text: "Placement Details",
			link: FILE_BASE_URL + "departmental-links/ece-placement.pdf",
		},
		{
			text: "Medals & Awards",
			link: FILE_BASE_URL + "departmental-links/ece-medals-and-awards.pdf",
		},
		{
			text: "Retired Faculty",
			link: FILE_BASE_URL + "departmental-links/ece-retired-faculty.pdf",
		},
		// {
		// 	text: "Students",
		// 	link: "",
		// },
		{
			text: "Events (Past & Upcoming)",
			link: FILE_BASE_URL + "departmental-links/ece-events.pdf",
		},
		{
			text: "Tabloid",
			link: FILE_BASE_URL + "EceTabloid.html",
		},
	],
	Research: [
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
	],
	curriculum: [
		{
			name: "B.Tech",
			desc: "A Bachelor of Technology is an undergraduate academic degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2018-19",
					link: SYLLABUS_BASE_URL + "BT_MT_2021_Final.pdf",
				},
			],
		},
		
	],
	facilities: [
		{
			name: "COMMUNICATION LAB-III",
			img: "",
			content:
				"The objective of this lab is to familiarise the students with microwave and optical communication techniques/technologies in microwave communication. The students study the operation and characteristics of microwave sources such as klystron and gunn diodes, load impedance and standing wave measurements, antenna gain and radiation pattern measurements. Optical communication deals with the study of the characteristics of the optical fibre and also analog and digital fibre links using LED and LASER sources.",
		},
		{
			name: "ADVANCED COMMUNICATION LAB",
			img: "",
			content:
				"This lab is an advancement of the communication lab-III where the students perform experiments using advanced equipments such as the vector network analyser, spectrum analyser, DTA and RCA.",
		},
		{
			name: "PG COMMUNICATION DESIGN AND SIMULATION LAB",
			img: "",
			content:
				"This lab course for M.Tech students teaches the students on microwave sources and devices, optical fibre and characteristic and design of microwave and optical fibre communication links. Students are also trained to handle and perform experiments using advanced equipment available in the lab.",
		},
		{
			name: "High Performance Communication Lab",
			img: "",
			content:
				" Facility created for design and analysis of wireless communication systems using advanced testing equipment’s like Spectrum Analyser, CDMA Trainer, High frequency DSO and OFDM trainer kit and Special simulation tools NetSim and QUALNET(Research)",
		},
		{
			name: "Freescale Systems Lab",
			img: "",
			content:
				"This lab has been established as a collaborative venture between Pondicherry Engineering College and M/S Freescale Semiconductors. India Pvt. Ltd. in accordance with the MoU signed during September, 2008. Freescale Semiconductors has donated ninety development tools which include Hardware and Software.",
		},
		{
			name: "COMMUNICATION LABORATORY-I",
			img: "",
			content:
				"Students construct and analyse circuits on analog communication transmitter and receiver. The students conduct experiment to understand the signals available at different stages of AM and FM receivers.",
		},
		{
			name: "COMMUNICATION LABORATORY-II",
			img: "",
			content:
				"The objective of this course is to familiarise the students with different blocks in digital communication different digital communication. Different digital modems and codecs are constructed and tested.",
		},
		{
			name: "Computer Networks Laboratory",
			img: "",
			content:
				"HP Core 2 Duo @2.9 GHz, 2GB RAM, 160GB HDD , Windows 7/ Windows 8. 100Mbps Internet facility. 10KVA UPS.",
		},
		{
			name: "Electronic Devices Laboratory",
			img: "",
			content:
				"The primary objective of the lab is to serve as a platform for understanding the operating principles of electron devices through experiments and also to study their characteristics.",
		},
		{
			name: "DIGITAL CIRCUITS DESIGN LAB",
			img: "",
			content:
				"The lab is intented for sophomores for practising their theoretical knowledge by designing. Constucting and testing the combinational as well as sequential digital circuits.",
		},
		{
			name: "ELECTRONICS CIRCUITS LAB-I",
			img: "",
			content:
				"The students are offered hands-on experience in designing, constructing and testing the analog electronics circuits. The students design the circuits, solder them and then test the performance experimentally. This lab nurtures all the skills that are essential to design and test advanced electronics and communication circuits. The students also validate the experimental results using PSpice computer simulator.",
		},
		{
			name: "ELECTRONICS CIRCUITS LAB-II",
			img: "",
			content:
				"This laboratory course is an advanced extension of Electonics circuits lab-I. The students design advanced analog and pulse circuits.They construct the circuit and test them. This lab builds the confidence of the students to design the advanced electronic circuits. The students study the impact of various design parameters on the performance of the circuits using PSpice computer simulator.",
		},
		{
			name: "Wireless Communication Laboratory",
			img: "Lenovo V530 - Intel® core TM :3-8100 CPU @3.60 GHz 4 GB RAM 64-bit Operating system X64 based processor - Windows 10. HP - Intel® core TM :i5-6500CPU@3.20 GHz 4 GB RAM 64-bit Operating system X64 based processor - Windows 10. Labview 2018 and my RIO tool kit",
			content: "",
		},
	],
};
