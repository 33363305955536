import React from "react";
import { DeanHeaderContainer } from "../Dean/components/Header";
import { getProfileImageUrl } from "../../config";
import { navItems } from "../Dean/components/Header";
import CurriculumSyllabus from "./CurruculumSyllabus";

const AcademicCurriculumSyllabus = () => {
	return (
		<div>
			<DeanHeaderContainer
				deanImg={getProfileImageUrl("hima.jpg")}
				deanName="Dr.S.Himavathi"
				deanDesignation="Dean (Academic Courses)"
				title={`ACADEMICS`}
				navItems={navItems}
			/>

			<CurriculumSyllabus />
		</div>
	);
};

export default AcademicCurriculumSyllabus;
