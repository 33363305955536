import React from 'react'
import img1 from "./assets/img1.jpeg"
import { FaBars } from "react-icons/fa"

function Navbar() {
  return (
    <div className='flex justify-between bg-red-800 text-white p-3 gap-4'>
      <div className='flex justify-evenly items-center gap-4'>
        <div><img src={img1} alt="logo" className='w-10' /></div>
        <div className='text-2xl'>The Design Club</div>
      </div>
      <div className='flex items-center'>
        <ul className='hidden lg:flex justify-evenly items-center'>
          <li className='px-4 text-xl py-3'>About</li>
          <li className='px-4 text-xl py-3'>Events</li>
          <li className='px-4 text-xl py-3'>Gallery</li>
          <li className='px-4 text-xl py-3'>Get Involved</li>
        </ul>
        <div className='lg:hidden block px-4'><FaBars className='text-2xl'/></div>
      </div>
    </div>
  )
}

export default Navbar