import React from 'react'
import { DeanHeaderContainer } from '../Dean/components/Header'
import { getProfileImageUrl } from '../../config'
import { navItems } from '../Dean/components/Header'
import Regulations from './Regulations'

const AcademicRegulations = () => {
  return (
    <div>
     <DeanHeaderContainer
	 		deanImg={getProfileImageUrl("hima.jpg")}
	 		deanName="Dr.S.Himavathi"
	 		deanDesignation="Dean (Academic Courses)"
	 		title={`ACADEMICS`}
	 		navItems={navItems}
	 	/>
    <Regulations />
    </div>
  )
}

export default AcademicRegulations