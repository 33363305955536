import React from 'react'

function ContentBox({ name, color, value }) {
  return (
    <div className={`col-span-5 p-4 lg:m-4 ${color === "yes" ? "bg-red-50" : ""}`}>
      
       <h1 className='text-red-800 font-semibold px-4'>{name}</h1>
       <div>
         {
           value.map((e)=>{
              return (
                <div className='mb-6 px-4'>{e}</div>
              )
           })
         }
       </div>
    </div>
  )
}

export default ContentBox