import { IT } from "./It/It";
import { CSE } from "./Cse/Cse";
import { EEE } from "./EEE/Eee";
import { EIE } from "./EIE/Eie";
import { ECE } from "./Ece/Ece";
import { ME } from "./Mech/Mech";
import { MT } from "./MT/MT";
import { Che } from "./Che/Che";
import { Civil } from "./Civil/Civil";
import { Chemistry } from "./Chemistry/Chemistry";
import { Physics } from "./Physics/Physics";
import { Maths } from "./Maths/Maths";
import { HSS } from "./HSS/Hss";
import { MBA } from "./MBA/MBA";

import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import { DeanHeaderContainer, navItems } from "../Dean/components/Header";
import { getProfileImageUrl } from "../../config";

export const departmentProps = [
	CSE,
	EEE,
	EIE,
	ECE,
	ME,
	IT,
	MT,
	Che,
	Civil,
	Chemistry,
	Physics,
	Maths,
	HSS,
	MBA,
].sort((d1, d2) => d1.About.ShortName.localeCompare(d2.About.ShortName));

function Departments() {
	return (
		<>
			<DeanHeaderContainer
				deanImg={getProfileImageUrl("hima.jpg")}
				deanName="Dr.K. VIVEKANANDAN"
				deanDesignation="Director (Academics and Educational Innovations)"
				title={`ACADEMICS`}
				navItems={navItems}
			/>
			<div>
				<iframe src="http://sites.google.com/ptuniv.edu.in/ptudeanacademics/"></iframe>
			</div>
			<div className="forms-templates__container">
				<div className="flex items-center py-7">
					<h1 className="m-3 pt-1 px-9 bg-orange-500 text-center text-2xl"></h1>
					<h1 className="m-1 py-3 text-center text-2xl font-bold text-red-800">
						DEPARTMENTS
					</h1>
				</div>
				<ul>
					{departmentProps.map((department, index) => (
						<li key={index} className="mb-2 uppercase">
							<Link
								to={department.basePath}
								className="block p-6 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 hover:scale-95 transition-transform duration-150 dark:border-gray-400 dark:hover:bg-red-200"
								style={{
									maxWidth: "750px",
								}}
							>
								{department.About.LongName}
							</Link>
						</li>
					))}
				</ul>
			</div>
			<Footer />
		</>
	);
}

export default Departments;
