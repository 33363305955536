import React from 'react'

function OfficeBearers() {
  return (
    <div>
      <h2 className='bg-red-800 p-3 pl-3 text-white text-2xl'>&nbsp;&nbsp;Office Bearers</h2>
      <div className='block px-9 sm:p-0 sm:flex justify-evenly items-center border-2 border-red-800'>
        <div className='py-5'>
          <h2 className='font-semibold text-2xl pt-3'>Faculty</h2>
           <ol className='design-club-ol flex my-7'>
             <li>Convenor</li>
             <li>-</li>
             <li>Sathiyamurthy K</li>
           </ol>
           <ol className='design-club-ol flex my-7'>
             <li>Convenor</li>
             <li>-</li>
             <li>Sathiyamurthy K</li>
           </ol>
           <ol className='design-club-ol flex my-7'>
             <li>Convenor</li>
             <li>-</li>
             <li>Sathiyamurthy K</li>
           </ol>
           <ol className='design-club-ol flex my-7'>
             <li>Convenor</li>
             <li>-</li>
             <li>Sathiyamurthy K</li>
           </ol>
        </div>
        <div>
          <h2 className='font-semibold text-2xl pt-3'>Students</h2>
           <ol className='design-club-ol flex my-7'>
             <li>General Secretary</li>
             <li>-</li>
             <li>Rupam Chirom (16CS141)</li>
           </ol>
           <ol className='design-club-ol flex my-7'>
             <li>Joint Secretary</li>
             <li>-</li>
             <li>Hemaachandar R (16CE120)</li>
           </ol>
           <ol className='design-club-ol flex my-7'>
             <li>Operations Chair</li>
             <li>-</li>
             <li>Najmu Sehar Wani (16CE134)</li>
           </ol>
           <ol className='design-club-ol flex my-7'>
             <li>Organization Chair</li>
             <li>-</li>
             <li>RamVignesh B (16CS140)</li>
           </ol>
        </div>
        <hr />
      </div>
    </div>
  )
}

export default OfficeBearers