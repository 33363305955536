import React from "react";
import { Link, Route } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import Footer from "../../components/Footer";
import { getProfileImageUrl } from "../../config";
import { DeanHeaderContainer, navItems } from "../Dean/components/Header";
import Calendar from "./Calendar";
import FormsTemplates from "./FormsTemplates";
import StudentInductionProgram from "./StudentInductionProgram";

import "./AcademicsServices.css";
import Circulars from "./Circulars";

const services = [
	{
		title: "Calendar",
		linkProps: { isNormalLink: false, to: "/academics/services/calendar" },
	},
	{
		title: "Circulars",
		linkProps: { isNormalLink: false, to: "/academics/services/circulars" },
	},
	{
		title: "Forms/Templates",
		linkProps: {
			isNormalLink: false,
			to: "/academics/services/forms-templates",
		},
	},
	{
		title: "Student induction program",
		linkProps: {
			isNormalLink: false,
			to: "/academics/services/student-induction-program",
		},
	},
];

export default function AcademicsServices({ services }) {
	return (
		<>
			<DeanHeaderContainer
				deanImg={getProfileImageUrl("hima.jpg")}
				deanName="Dr.S.Himavathi"
				deanDesignation="Dean (Academic Courses)"
				title={`ACADEMICS`}
				navItems={navItems}
			/>
			<div className="academics-services-container">
				<div className="flex items-center py-7">
					<p className="m-3 pt-1 px-9 bg-orange-500 text-center text-2xl"></p>
					<h1 className="m-1 py-3 text-center text-2xl font-bold text-red-800">
						SERVICES
					</h1>
				</div>
				<ul className="academics-services__list">
					{services.map((service) => {
						const {
							title,
							linkProps: { isNormalLink, ...restLinkProps },
						} = service;
						let link = null;

						if (isNormalLink) {
						} else {
							link = (
								<Link {...restLinkProps} className="p-3 mb-5 rounded">
									{title}
								</Link>
							);
						}

						return (
							<li key={title}>
								{link}
								<FaArrowRight className="academics-services__link-arrow" />
							</li>
						);
					})}
				</ul>
			</div>
			<Footer />
		</>
	);
}

export const AcademicsServicesRoutes = (
	<>
		<Route
			path="/academics/services"
			element={<AcademicsServices services={services} />}
		/>
		<Route path="/academics/services/calendar" element={<Calendar />} />
		<Route path="/academics/services/circulars" element={<Circulars />} />
		<Route
			path="/academics/services/forms-templates"
			element={<FormsTemplates />}
		/>
		<Route
			path="/academics/services/student-induction-program"
			element={<StudentInductionProgram />}
		/>
	</>
);
