import { FILE_BASE_URL, SYLLABUS_BASE_URL } from "../../../config";

import Image from "../../../../src/components/assets/EEE_HOD.jpg";

import Dimg from "../../../../src/components/assets/eee.jpg";

export const EEE = {
	basePath: "/eee",

	navLinks: [
		{
			to: "/about",
			text: "DEPARTMENT",
		},
		{
			to: "/faculty",
			text: "FACULTY",
		},
		{
			to: "/syllabus",
			text: "CURRICULUM",
		},
		{
			href: FILE_BASE_URL + "eee-research.html",
			text: "RESEARCH",
		},
		{
			to: "/facilities",
			text: "FACILITIES",
		},
	],

	About: {
		DeptName: "Electrical and Electronic Engineering",
		ShortName: "EEE",
		LongName: "DEPARTMENT OF Electrical and Electronic Engineering",

		DeptImg: Dimg,

		count: [
			{
				name: "B.Tech Students",
				count: 235,
			},
			{
				name: "M.Tech Students",
				count: 12,
			},
			{
				name: "Ph.d Scholars",
				count: 5,
			},
		],

		about: [
			`The Department of Electrical and electronic engineering has well experienced faculty, skilled technical staff and well equipped laboratories. It offers one undergraduate program B.Tech. (Electrical and electronic engineering). It aims at providing high quality training to students through the latest in Electrical and electronic engineering.`,
			`In order to maintain the standard of education, it constantly upgrades the academic syllabi so as to keep the students well to deal with changing trends in the field of Electrical and electronic engineering. It also emphasizes on Information Security, Image Processing, Software Engineering, Optical and Mobile Networks, Soft Computing, Complex Event Processing, Data Mining, Web Technology and several other recent topics.`,
			`The Department of Electrical and electronic engineering is accredited by NBA (National Board of Accreditation). So far, eight batches of students have successfully completed the degree course with flying colours.`,
		],
		vision: [
			{
				point:
					"Strive through teaching, learning and research to create revered Electrical and Electronics Engineers with strong theoretical knowledge, good practical exposure, critical thinking and innovation, amalgamated to achieve high quality professionals with social and ethical values.",
			},
		],
		Mission: [
			{
				point:
					"To achieve academic excellence at national and international level ",
			},
			{
				point:
					"To focus more on practical aspects with a strong fundamental theoretical knowledge",
			},
			{
				point:
					"To imbibe in its students, the ability to think critically, creatively for developing innovative ideas in - order to meet out the social demand in perspective to Electrical and Electronics Engineering",
			},
			{
				point:
					"To collaborate with other academic and research institutes in - order to strengthen the academic and research activities",
			},
			{ point: "To train the students in achieving their educational goals" },
			{
				point:
					"To impart emerging technical skills among the students so as to make them competent technocrats",
			},
		],

		PEOs: [
			{
				point: (
					<a href={FILE_BASE_URL + "eee-b.tech.pdf"} target="_blank">
						B.Tech PEOs
					</a>
				),
			},
			{ point: "M.Tech" },
		],

		Hod: {
			name: "Dr. K. Elanseralathan",
			designation: "Professor and Head",
			img: Image,
			phnNo: "1234567890",
			email: "hod.eee@ptuniv.edu.in",
			content: `Welcome to the Dept. of Electrical and Electronics Engineering! It gives me great pleasure
			to introduce the eminent Dept. of Electrical and Electronics Engineering.
			It has a strong undergraduate programme B.Tech. in Electrical and Electronics
			Engineering. It offers one post graduate programme M.Tech. in Electrical Devices and
			Control and Ph.D. degree. It aims at providing high quality training to students through
			the latest in Electrical and Electronic Engineering.
			The Department has a dedicated team of faculty members with 9 Professors, 5 Associate
			Professors and 2 Assistant Professors. I am proud to mention that all faculty of this
			eminent department are Doctorates and have specialized in key research areas. The
			faculty members enable the students to obtain strong knowledge of fundamentals and
			give them excellent analytical training. The department is ably supported by technical staff
			consisting of Senior instructors, Mechanics and Lab attendants who assist the faculty and
			students.
			In order to maintain the standard of education, it constantly upgrades the academic syllabi
			so as to keep the students well to deal with changing trends in the field of Electrical and
			Electronic Engineering. It also emphasizes on Information Security, Image Processing,
			Software Engineering, Optical and Mobile Networks, Soft Computing, Complex and
			several other techniques.
			The department of Electrical and Electronics Engineering is accredited by NBA (National
			Board of Accreditation). So far, eight batches of students have successfully completed
			the degree course with flying colors.
			Our department strives through teaching, learning and research to create revered
			Electrical and Electronics Engineers with strong theoretical knowledge, good practical
			exposure, critical thinking and innovation, amalgamated to achieve high quality
			professionals with social and ethical values.
			The main mission of the department is to achieve academic excellence at national and
			international level. To focus more on practical aspects with a strong fundamental
			theoretical knowledge. To imbibe in its students, the ability to think critically, creatively for
			developing innovative ideas in order to meet out the social demand in perspective to
			Electrical and Electronics Engineering. To collaborate with other academic and research
			institutes. To strengthen the academic and research activities. To implement the
			emerging techniques to the students.
			I wish all the very best for your future in this department!`,
		},
	},
	departmentalLinks: [
		{
			text: "Departmental Committies",
			link: FILE_BASE_URL + "departmental-links/eee-committees.pdf",
		},
		{
			text: "Notable Alumni",
			link: FILE_BASE_URL + "departmental-links/eee-alumni.pdf",
		},
		{
			text: "Placement Details",
			link: FILE_BASE_URL + "departmental-links/eee-placement.pdf",
		},
		{
			text: "Medals & Awards",
			link: FILE_BASE_URL + "departmental-links/eee-medals-and-awards.pdf",
		},
		{
			text: "Retired Faculty",
			link: FILE_BASE_URL + "departmental-links/eee-retired-faculty.pdf",
		},
		// {
		// 	text: "Students",
		// 	link: "",
		// },
		{
			text: "Events (Past & Upcoming)",
			link: FILE_BASE_URL + "departmental-links/eee-events.pdf",
		},
		{
			text: "Innovation",
			link: FILE_BASE_URL + "eee-innovation.pdf",
		},
	],
	Research: [
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
	],
	curriculum: [
		{
			name: "B.Tech",
			desc: "A Bachelor of Technology is an undergraduate academic degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2018-19",
					link: SYLLABUS_BASE_URL + "5_BT_EE_1819.pdf",
				},
			],
		},
		{
			name: "M.Tech",
			desc: "A degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2020-21",
					link: SYLLABUS_BASE_URL + "17_MT_EE_ED_2021.pdf",
				},
			],
		},
	],
	facilities: [
		{
			name: "Basic Electrical Engineering Laboratory",
			img: "",
			content:
				" practical oriented electronic circuits that are very essential for the students to solidify their theoretical concepts. This Manual provides a communication bridge between the theory and practical world of the electronic circuits.",
		},
		{
			name: "Electrical Machine Laboratory – I",
			img: "",
			content:
				"The purpose of this laboratory is to train the students to be familiar with the software and hardware of microprocessors so that they can gain enough experiences to meet the demand of the microprocessor era. The facilities in the laboratory enable students to build a firm background in microprocessor hardware as well as software .",
		},
		{
			name: "Electronics Laboratory – I",
			img: "",
			content:
				"Embedded Systems Design Lab The ESD Lab is used to teach students to design and simulate digital circuits. The lab includes Oscilloscopes, Arbitrary Function Generators, Power Supplies and Multimeters. Many high tech software packages are installed to allow students to create embedded systems.",
		},
		{
			name: "Electrical Machines Laboratory – II",
			img: "",
			content:
				"The word “Transducer” is the collective term used for both Sensors which can be used to sense a wide range of different energy forms such as movement, electrical signals, radiant energy, thermal or magnetic energy, etc., and Actuators which can be used to switch voltages or currents.",
		},
		{
			name: "Electronics Laboratory – II",
			img: "",
			content:
				"Multi Process trainer, Multi variable Laboratory type Level Process, Calibration test bench, PLC trainer, Distributed Control system, Control Valve Characteristics Setup,",
		},
		{
			name: "Electronics Laboratory – III",
			img: "",
			content:
				"Virtual instrumentation is the use of customizable software and modular measurement hardware to create user-defined measurement systems, called virtual instruments. Traditional hardware instrumentation systems are made up of fixed hardware components, such as digital multimeters and oscilloscopes that are completely specific to their stimulus, analysis, or measurement function. Because of their hard-coded function, these systems are more limited in their versatility than virtual instrumentation systems.",
		},
		{
			name: "Microprocessor and Microcontroller Laboratory",
			img: "",
			content:
				"Virtual instrumentation is the use of customizable software and modular measurement hardware to create user-defined measurement systems, called virtual instruments. Traditional hardware instrumentation systems are made up of fixed hardware components, such as digital multimeters and oscilloscopes that are completely specific to their stimulus, analysis, or measurement function. Because of their hard-coded function, these systems are more limited in their versatility than virtual instrumentation systems.",
		},
		{
			name: "Power Electronics Laboratory",
			img: "",
			content:
				"Virtual instrumentation is the use of customizable software and modular measurement hardware to create user-defined measurement systems, called virtual instruments. Traditional hardware instrumentation systems are made up of fixed hardware components, such as digital multimeters and oscilloscopes that are completely specific to their stimulus, analysis, or measurement function. Because of their hard-coded function, these systems are more limited in their versatility than virtual instrumentation systems.",
		},
		{
			name: "Solid State System Laboratory",
			img: "",
			content:
				"Virtual instrumentation is the use of customizable software and modular measurement hardware to create user-defined measurement systems, called virtual instruments. Traditional hardware instrumentation systems are made up of fixed hardware components, such as digital multimeters and oscilloscopes that are completely specific to their stimulus, analysis, or measurement function. Because of their hard-coded function, these systems are more limited in their versatility than virtual instrumentation systems.",
		},
		{
			name: "Electrical Drives Laboratory ",
			img: "",
			content:
				"Virtual instrumentation is the use of customizable software and modular measurement hardware to create user-defined measurement systems, called virtual instruments. Traditional hardware instrumentation systems are made up of fixed hardware components, such as digital multimeters and oscilloscopes that are completely specific to their stimulus, analysis, or measurement function. Because of their hard-coded function, these systems are more limited in their versatility than virtual instrumentation systems.",
		},
	],
};
