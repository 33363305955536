import React from 'react';
import "./table.css"
import { FaAward } from "react-icons/fa"

/* 
  Row component written as a simple function:
  https://facebook.github.io/react/docs/components-and-props.html#functional-and-class-components
  
  Any components that do not have state should be written this way,
  see: https://medium.com/@housecor/react-stateless-functional-components-nine-wins-you-might-have-overlooked-997b0d933dbc
*/

let dummy_array = []

for(let i=0; i<16; i++){
    dummy_array.push(i);
}

export default function MedalAwards(){
  return(
    <>
       <div class="award_container my-10">
        <div class="head ">Awards and Medals</div>
        <p className="py-9 my-8 medal-topic flex justify-center">
            <div className="px-3 pt-2"><FaAward /></div>
            Faculty Awards
            <div className="px-3 pt-2"><FaAward /></div>
        </p>
        <table class="shadow-xl border border-gray-100">
            <thead>
                <tr>
                    <th title="Field #1">Sl. No.</th>
                    <th title="Field #2">Award</th>
                    <th title="Field #3">Faculty Name</th>
                    <th title="Field #4">Place &amp; Year</th>
                </tr>
            </thead>
            <tbody>
               {
                   dummy_array.map( e => {
                          return (
                            <tr>
                               <td className={(e%2 !== 0) && "bg-gray-100"}>{e}</td>
                               <td className={(e%2 !== 0) && "bg-gray-100"}>Global Teacher Prize</td>
                               <td className={(e%2 !== 0) && "bg-gray-100"}>Dr. Rahul Gandhi</td>
                               <td className={(e%2 !== 0) && "bg-gray-100"}>PTU, 2020</td>
                           </tr>
                          )
                   })
               }                
            </tbody>
        </table>
        <br />
        <p className="py-19 my-8 medal-topic flex justify-center">
            <div className="px-3 pt-2"><FaAward /></div>
            Student Awards
            <div className="px-3 pt-2"><FaAward /></div>
        </p>
        <table class="shadow-xl border border-gray-100">
            <thead>
                <tr>
                    <th title="Field #1">Sl. No.</th>
                    <th title="Field #2">Award</th>
                    <th title="Field #3">Student Name</th>
                    <th title="Field #4">Place &amp; Year</th>
                </tr>
            </thead>
            <tbody>
                {
                   dummy_array.map( e =>{
                          return (
                            <tr>
                               <td className={(e%2 !== 0) && "bg-gray-100"}>{e}</td>
                               <td className={(e%2 !== 0) && "bg-gray-100"}>Global Student Prize</td>
                               <td className={(e%2 !== 0) && "bg-gray-100"}>Ashneer Grover</td>
                               <td className={(e%2 !== 0) && "bg-gray-100"}>PTU, 2020</td>
                            </tr>
                          )
                   })
               }   
            </tbody>
        </table>
    </div>
    </>
  )
};
  /*
   * Render the above component into the div#app
   */