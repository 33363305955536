import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import ReactModal from "react-modal";
import { SWRConfig } from "swr";
import store from "./store";
import App from "./App";

ReactModal.setAppElement("#root");

ReactDOM.render(
	<React.StrictMode>
		<SWRConfig
			value={{
				refreshInterval: 10 * 1000,
			}}
		>
			<Provider store={store}>
				<App />
			</Provider>
		</SWRConfig>
	</React.StrictMode>,
	document.getElementById("root")
);
