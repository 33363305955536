import React from "react";
import { documents } from "./contacts";

const Authority = ({ value }) => {
	let data = documents[value];
	return (
		<div className="mb-5 bg-gray-100 border border-gray-400 p-5 shadow-xl">
			<p className="text-xl font-bold">{data.authority}</p>
			<div className="my-2">
				<div>Faculty Incharge : {data.facultyInCharge}</div>
				<div>Phone : {data.phone}</div>
			</div>
		</div>
	);
};

export default Authority;
