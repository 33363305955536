import { getImageUrl } from "../../../../config";

// retired faculty staffs
export let supportingStaff = [
	{
		name: "Lorem ipsum dolor sitLorem ipsum dolor sit",
		img: getImageUrl("Unknown_person.jpg"), //unknown
		Subject: "Lorem ipsum dolor sit",
		email: "Lorem ipsum dolor sit@pec.edu",
		number: 0,
	},
	{
		name: "Lorem ipsum dolor sitLorem ipsum dolor sit",
		img: getImageUrl("Unknown_person.jpg"), //unknown
		Subject: "Lorem ipsum dolor sit",
		email: "Lorem ipsum dolor sit@pec.edu",
		number: 0,
	},{
		name: "Lorem ipsum dolor sitLorem ipsum dolor sit",
		img: getImageUrl("Unknown_person.jpg"), //unknown
		Subject: "Lorem ipsum dolor sit",
		email: "Lorem ipsum dolor sit@pec.edu",
		number: 0,
	},{
		name: "Lorem ipsum dolor sitLorem ipsum dolor sit",
		img: getImageUrl("Unknown_person.jpg"), //unknown
		Subject: "Lorem ipsum dolor sit",
		email: "Lorem ipsum dolor sit@pec.edu",
		number: 0,
	},{
		name: "Lorem ipsum dolor sitLorem ipsum dolor sit",
		img: getImageUrl("Unknown_person.jpg"), //unknown
		Subject: "Lorem ipsum dolor sit",
		email: "Lorem ipsum dolor sit@pec.edu",
		number: 0,
	},{
		name: "Lorem ipsum dolor sitLorem ipsum dolor sit",
		img: getImageUrl("Unknown_person.jpg"), //unknown
		Subject: "Lorem ipsum dolor sit",
		email: "Lorem ipsum dolor sit@pec.edu",
		number: 0,
	},{
		name: "Lorem ipsum dolor sitLorem ipsum dolor sit",
		img: getImageUrl("Unknown_person.jpg"), //unknown
		Subject: "Lorem ipsum dolor sit",
		email: "Lorem ipsum dolor sit@pec.edu",
		number: 0,
	},
];

