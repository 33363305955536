export const documents = [
	{
		authority: "General Enquiry",
		// facultyInCharge: "Registrar",
		facultyInCharge: "-",
		email: "registrar@ptuniv.edu.in",
		phone: "0413-2655281",
	},
	{
		authority: "AntiRagging",
		// facultyInCharge: "Dean (Students)",
		facultyInCharge: "-",
		email: "dean.students@ptuniv.edu.in",
		phone: "944320299",
	},
	{
		authority: "Academic Grievance Redressal",
		// facultyInCharge: "Dr. E. Ilavarasan",
		facultyInCharge: "-",
		email: "grc@ptuniv.edu.in",
		phone: "9443084714",
	},
	{
		authority: "Hostel Warden",
		// facultyInCharge: "-",
		facultyInCharge: "-",
		email: "warden@ptuniv.edu.in & deputywarden@ptuniv.edu.in",
		phone: "-",
	},
	{
		authority: "Campus Facility Grievance Redressal",
		// facultyInCharge: "-",
		facultyInCharge: "-",
		email: "-",
		phone: "-",
	},
	{
		authority: "Complaints on Sexual Harassement",
		// facultyInCharge: "Dr. Santhi Baskaran",
		facultyInCharge: "-",
		email: "santhibaskaran@pec.edu",
		phone: "9443616935",
	},
	// {
	// 	authority: "Counselling Request",
	// 	facultyInCharge: "Dr. Shanthi Simon",
	// 	email: "shanthisimon@ptuniv.edu.in",
	// 	phone: "9894488322",
	// },
	{
		authority: "Website Feedback",
		// facultyInCharge: "Design Club Handle",
		facultyInCharge: "-",
		email: "info@ptuniv.edu.in",
		phone: "-",
	},
];
