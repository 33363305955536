/**
 *
 * IMPORTANT
 *
 *
 *
 *
 *
 * When a new route is added.
 * Add the path to the generateRoutes.js file
 *
 *
 *
 *
 *
 */

import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import HomeScreen from "./pages/Home";

import "./App.css";

import Departments, { departmentProps } from "./pages/departments";

import Department from "./pages/departments/Department";
import ChancellorMessage from "./pages/ChancellorsMessage/Chancellor";
import ViceChancellorMessage from "./pages/ChancellorsMessage/ViceChancellor";
import SGR from "./pages/SGR";
import Contacts from "./pages/contacts";
import DesignClub from "./pages/DesignClub";
import Academics from "./pages/academics";
import AcademicRegulations from "./pages/AcademicRegulations";
import AcademicCurriculumSyllabus from "./pages/AcademicCurriculumSyllabus";
import { AcademicsServicesRoutes } from "./pages/AcademicsServices";
import FourCPage from "./components/4C";
import FourCSubPage from "./components/4C/fourcsubpage";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { API_BASE_URL } from "./config";

function App() {
	useEffect(() => {
		async function fetch4cSubDomains() {
			try {
				const response = await axios.get(API_BASE_URL + `FourCList.php`);
				setFourCSubDomains(response.data);
			} catch (e) {}
		}
		fetch4cSubDomains();
	}, []);

	const [fourCSubDomains, setFourCSubDomains] = useState([]);

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/*" element={<HomeScreen />} />
				<Route path="departments" element={<Departments />} />
				{departmentProps.map((department, index) => {
					console.log(department);
					return (
						<Route
							key={index}
							path={department.basePath + "/*"}
							element={<Department department={department} />}
						/>
					);
				})}
				{fourCSubDomains &&
					fourCSubDomains.map((element) => {
						console.log(fourCSubDomains);
						return [
							<Route
								path={"4c/"+ element["four_c_subdomain_name"]}
								element={
									<FourCPage subdomainName={element["four_c_subdomain_name"]} />
								}
							/>,
							<Route
								path={"4c/"+ element["four_c_subdomain_name"] + "/:subPageName"}
								element={
									<FourCSubPage
										parentDomainName={element["four_c_subdomain_name"]}
									/>
								}
							/>,
						];
					})}

				<Route path="/vc-message" element={<ViceChancellorMessage />} />
				<Route path="/chancellor-message" element={<ChancellorMessage />} />
				<Route path="/sgr" element={<SGR />} />
				<Route path="/contacts" element={<Contacts />} />

				<Route path="/academics" element={<Academics />} />
				<Route
					path="/academics/regulations"
					element={<AcademicRegulations />}
				/>
				<Route
					path="/academics/curriculum-&-syllabus"
					element={<AcademicCurriculumSyllabus />}
				/>

				{/* ACADEMICS SERVICES ROUTES */}
				{AcademicsServicesRoutes}

				<Route path="/*" element={<HomeScreen />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
