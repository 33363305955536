import { FILE_BASE_URL, getImageUrl, SYLLABUS_BASE_URL } from "../../../config";

import Image from "../../../../src/components/assets/harishk.jpg"

export const Physics = {
	basePath: "/phy",

	navLinks: [
		{
			to: "/about",
			text: "DEPARTMENT",
		},
		{
			to: "/faculty",
			text: "FACULTY",
		},
		{
			to: "/syllabus",
			text: "CURRICULUM",
		},
		{
			to: "/research",
			text: "RESEARCH",
		},
		{
			to: "/facilities",
			text: "FACILITIES",
		},
	],

	About: {
		DeptName: "PHYSICS",
		ShortName: "PHY",
		LongName: "DEPARTMENT OF PHYSICS",

		DeptImg: "",

		count: [
			{
				name: "M.Sc Students",
				count: 16,
			},
		],

		about: [
			"The Department of Physics has been functioning right from the inception of the institution in 1985. The Department initially functioned as a supporting department catering to the demands of the under-graduate students. The Department started a post-graduate course in Material Science and Technology in 1992. The M.Phil. and Ph.D. research programs were begun in 1997. The Department has a well-equipped Physics Laboratory and P.G. Material Science &amp; Technology Laboratory.",
			"The laboratories of the department are equipped with state of the art equipment some of which include the following: Flaw Detector, Ultrasonic Magnetic Particle Detector, Eddy Current Detector, Strain analysis Equipment with DAS computer Stand-alone System, Viscometer (milli Pascal to mega Pascal range), Vacuum coating units with Electron Beam Gun, Four-probe, Two-probe Conductivity Measurement Setup, Differential Thermal Analyzer, Thermo Luminescence Set-up, High power Electro Magnet with imported gauss meters to measure axial and transverse magnetic fields, Electronic Balance Micro and Milligram Accuracy, Ball Mill (planetary type), and LCR meter.",
			"The department has five highly qualified and experienced faculty members. The Department has produced about 200 M.Sc (MS&amp;T), 30 M.Phills and 19 Ph.D Degrees. The department has produced more than 80 research papers in the international reputed, peer viewed journals like, Physical Review B, Journal of Applied physics, IEEE, Elsevier and Springer. Most of our PG and research candidates are well placed in several reputed Universities and Research Institutes (including IISc Bangalore, ISRO, IIT H) and in many Engineering Colleges as Physics and Chemistry Faculty. Others have found employment in IT and other industries.",
		],
		vision: [
			{
				point:
					"To provide a platform for enriching and creating the knowledge through teaching and research in Physics.",
			},
			{
				point:
					"To improve the technical skill as well as value based education, for the development of science and technology to build a strong nation.",
			},
		],

		Mission: [
			{
				point:
					"To groom our young minds to become professionally and morally sound humans for building a healthy nation.",
			},
			{
				point:
					"To motivate students through innovations, quality research publications and patents in Physics as well as in interdisciplinary areas and taking science and technology to the gross roots.",
			},
			{
				point: "Empowerment through self sufficiency.",
			},
		],

		PEOs: [
			{
				point: (
					<a href={FILE_BASE_URL + "phy-b.tech.pdf"} target="_blank">
						B.Tech PEOs
					</a>
				),
			},
			{ point: "M.Tech" },
		],

		Hod: {
			name: "Dr. HARISH KUMAR",
			designation: "Professor and Head",
			img: Image,
			// phnNo: "",
			// email: "",
			// content: "",
		},
	},
	departmentalLinks: [
		{
			text: "Departmental Committies",
			link: FILE_BASE_URL + "departmental-links/phy-committees.pdf",
		},
		{
			text: "Notable Alumni",
			link: FILE_BASE_URL + "departmental-links/phy-alumni.pdf",
		},
		{
			text: "Placement Details",
			link: FILE_BASE_URL + "departmental-links/phy-placement.pdf",
		},
		{
			text: "Medals & Awards",
			link: FILE_BASE_URL + "departmental-links/phy-medals-and-awards.pdf",
		},
		{
			text: "Retired Faculty",
			link: FILE_BASE_URL + "departmental-links/phy-retired-faculty.pdf",
		},
		// {
		// 	text: "Students",
		// 	link: "",
		// },
		{
			text: "Events (Past & Upcoming)",
			link: FILE_BASE_URL + "departmental-links/phy-events.pdf",
		},
	],
	Research: [],
	curriculum: [
		{
			name: "M.Sc",
			desc: "A Bachelor of Technology is an undergraduate academic degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "MSC_PHY_2021",
					link: SYLLABUS_BASE_URL + "MSC_PHY_2021.pdf",
				},
			],
		},
	],
	facilities: [],
};
