import React from "react";
import Overview from "./Overview";
import "./style.css";
import { getProfileImageUrl } from "../../config";
import { DeanHeaderContainer } from "../Dean/components/Header";
import { navItems } from "../Dean/components/Header";

function Academics() {
	return (
		<>
			<DeanHeaderContainer
				deanImg={getProfileImageUrl("hima.jpg")}
				deanName="Dr.S.Himavathi"
				deanDesignation="Dean (Academic Courses)"
				title={`ACADEMICS`}
				navItems={navItems}
			/>
			<Overview />
		</>
	);
}

export default Academics;
