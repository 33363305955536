import React from "react";
import Footer from "../../../components/Footer";
import FormsTable from "./FormsTable";
import { FORMS_TEMPLATES_BASE_URL, getProfileImageUrl } from "../../../config";
import { DeanHeaderContainer, navItems } from "../../Dean/components/Header";

import "../Calendar/Calendar.css";
import "./FormsTemplates.css";
import FormsTemplateSection from "./FormsTemplateSection";

function FormsTemplates() {
	return (
		<>
			<DeanHeaderContainer
				deanImg={getProfileImageUrl("hima.jpg")}
				deanName="Dr.S.Himavathi"
				deanDesignation="Dean (Academic Courses)"
				title={`ACADEMICS`}
				navItems={navItems}
			/>
			<div>
				<FormsTemplateSection
					title="Student related Forms"
					tableProps={{
						headers: [{ text: "Form Number" }, { text: "Name" }],
						rows: [
							{
								title: "SF 01",
								link: {
									href: FORMS_TEMPLATES_BASE_URL + "SF-01.pdf",
									text: "Leave Application for OD or Medicals grounds",
								},
							},
							{
								title: "SF 02",
								link: {
									href: FORMS_TEMPLATES_BASE_URL + "SF-02.pdf",
									text: "Application for Temporary break",
								},
							},
							{
								title: "SF 03",
								link: {
									href: FORMS_TEMPLATES_BASE_URL + "SF-03.pdf",
									text: "Readmission after temporary break",
								},
							},
							{
								title: "SF 04",
								link: {
									href: FORMS_TEMPLATES_BASE_URL + "SF-04.pdf",
									text: "Application for Permanent Discontinuance",
								},
							},
							{
								title: "SF 05",
								link: {
									href: FORMS_TEMPLATES_BASE_URL + "SF-05.pdf",
									text: "Internship Form",
								},
							},
							{
								title: "SF 06",
								link: {
									href: FORMS_TEMPLATES_BASE_URL + "SF-06.pdf",
									text: "Project cum Internship",
								},
							},
							{
								title: "SF 07",
								link: {
									href: FORMS_TEMPLATES_BASE_URL + "SF-07.pdf",
									text: "NPTL Cource Registered List",
								},
							},
						],
					}}
				/>

				<FormsTemplateSection
					title="Department related Academic forms"
					tableProps={{
						headers: [{ text: "Form Number" }, { text: "Name" }],
						rows: [
							{
								title: "DF 01",
								link: {
									href: FORMS_TEMPLATES_BASE_URL + "ACA form CC-01.pdf",
									text: "CCM constitution template",
								},
							},
							{
								title: "DF 02",
								link: {
									href: FORMS_TEMPLATES_BASE_URL + "ACA Form CC-02.pdf",
									text: "CCM Minutes template",
								},
							},
							{
								title: "DF 03",
								link: {
									href:
										FORMS_TEMPLATES_BASE_URL + "DOCUMENT CHECKLIST_2022.pdf",
									text: "List of docs for academic audit",
								},
							},
							{
								title: "DF 04",
								link: {
									href: FORMS_TEMPLATES_BASE_URL + "DF-04.pdf",
									text: "Academic audit manual",
								},
							},
						],
					}}
				/>
			</div>
			<Footer />
		</>
	);
}

export default FormsTemplates;
