import React, { useState } from "react";
import { BiSearchAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { getImageUrl } from "../../../../config";
import { scrollToTop } from "../../../../utils/common";
import NavBar from "../NavBar";

export const navItems = [
	{
		isNormalLink: false,
		to: "/academics",
		children: <p>ACADEMICS</p>,
		onClick: scrollToTop,
	},
	{
		isNormalLink: false,
		to: "/academics/regulations",
		children: <p>REGULATION</p>,
		onClick: scrollToTop,
	},
	{
		isNormalLink: false,
		to: "/academics/curriculum-&-syllabus",
		children: <p>CURRICULUM</p>,
		onClick: scrollToTop,
	},
	{
		isNormalLink: false,
		to: "",
		children: <p>CCE</p>,
		onClick: scrollToTop,
	},
	{
		isNormalLink: false,
		to: "/academics/services",
		children: <p>SERVICES</p>,
		onClick: scrollToTop,
	},
];

export default function DeanHeader({
	title,
	deanImgSrc,
	deanName,
	deanDesignation,
}) {
	return (
		<div className="deptHead">
			<div className="header_dept flex justify-around">
				<div className="title_dept flex items-center">
					<Link
						to="/"
						style={{ padding: 0, margin: 0, textDecoration: "none" }}
					>
						<img
							src={getImageUrl("PTU_Logo-color.png")}
							alt="aaaaaaaaaaaaa"
							className="img-top"
						/>
					</Link>
					<h3>{title}</h3>
				</div>
				<div className="searchbar_dept">
					<input type="text" placeholder="Search here" />
					<BiSearchAlt className="search_icon" />
				</div>
			</div>
			<div className="imgsec">
				<img src={getImageUrl("opt-landing-page2.jpg")} alt="" />
			</div>

			<div className="hod-card__container">
				<div className="hod-card__parts">
					<img src={deanImgSrc} alt="" />
				</div>
				<div className="hod-card__parts hod-card__parts--fill">
					<h4
						className="text-2xl"
						style={{ fontSize: "23px", fontWeight: "700" }}
					>
						{deanName}
					</h4>
					<h6 className="hod-card-title">{deanDesignation}</h6>
				</div>
			</div>
		</div>
	);
}

export function DeanHeaderContainer({
	navItems,
	title,
	deanImg,
	deanName,
	deanDesignation,
}) {
	const [isHidden, setIsHidden] = useState(true);

	const toggleNav = () => setIsHidden((prvStatus) => !prvStatus);

	return (
		<>
			<DeanHeader
				title={title}
				deanImgSrc={deanImg}
				deanName={deanName}
				deanDesignation={deanDesignation}
			/>
			<NavBar
				navItems={navItems}
				onMenuIconClick={toggleNav}
				isSmallNavBarHidden={isHidden}
			/>
		</>
	);
}
