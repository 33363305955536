import React from 'react'
import { data } from "./DesignClub"

function Projects() {
  return (
    <div className='col-span-2 p-4'>
        <div className='flex'>
           <div className='flex items-center'>
                <h1 className='font-semibold px-7 text-red-800 border-b-4 border-red-500'>{""}</h1>
           </div>
         <h1 className='mx-4 font-semibold  text-red-800'>Projects</h1>
        </div>
        <div className='grid grid-cols-4'>
            {
                data.projects.map((e) => {
                    return <div className='shadow-lg border-2 col-span-2 p-9 m-2 mb-0'> 
                             <h1 className='font-semibold mb-3'>{e.count}+</h1>
                             <h2 className='font-semibold'>{e.name}</h2>
                           </div>
                })
            }
        </div>
    </div>
  )
}

export default Projects