import React from "react";

import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";

function NavBar({ navItems, onMenuIconClick, isSmallNavBarHidden }) {
	return (
		<>
			<div className="navbar_for_dept justify-right lg:justify-center">
				<div className="bars lg:hidden block" onClick={onMenuIconClick}>
					<FaBars />
				</div>
				<div
					className={`small-navbar lg:hidden block ${
						isSmallNavBarHidden ? "hidden" : ""
					}`}
				>
					{navItems.map((navItem, index) => {
						const { isNormalLink, ...rest } = navItem;
						if (!isNormalLink) {
							return (
								<Link
									key={index}
									{...rest}
									onClick={(e) => {
										// to hide the mobile nav when the link is clicked
										if (onMenuIconClick) onMenuIconClick();

										if (rest.onClick) rest.onClick(e);
									}}
								/>
							);
						}
						return null;
					})}
				</div>
				<div className="nav-inside lg:flex lg:justify-center hidden">
					{navItems.map((navItem, index) => {
						const { isNormalLink, ...rest } = navItem;
						if (!isNormalLink) {
							return <Link key={index} {...rest} />;
						}
						return null;
					})}
				</div>
			</div>
		</>
	);
}

export default NavBar;
