import React from "react";
import { useState, useRef } from "react";
import { documents } from "./contacts";
import MailForm from "./form";
import { MainNavContainer } from "../../components/MainNav";
import Footer from "../../components/Footer";
import Authority from "./authority";
import MobileNavBar from "../../components/MainNav/MobileNavBar";
import useDimension from "../../hooks/useDimension";

const Contacts = () => {
	let [selectDepartment, setSelectDepartment] = useState(0);
	const { dimension } = useDimension();
	const navRef = useRef();

	const openMobileNav = () => {
		if (!navRef.current) return;
		navRef.current.openMobileMenu();
	};

	return (
		<>
			{dimension.width < 800 && <MobileNavBar onClick={openMobileNav} />}
			<MainNavContainer navRef={navRef} />
			<div className="py-7 lg:px-9 mx-9">
				<div className="block lg:grid lg:grid-cols-7 lg:col-reverse justify-evenly items-baseline">
					<MailForm value={selectDepartment} />
					<div className="block md:col-span-2 my-7 lg:my-0">
						<Authority value={selectDepartment} />
						<div className="flex mb-5 justify-center">
							<p>------&nbsp;&nbsp;</p>
							<p className="text-red-600 font-bold text-capatilize">select</p>
							<p>&nbsp;&nbsp;------</p>
						</div>
						{documents.map((e, index) => {
							return (
								<div
									onClick={() => {
										setSelectDepartment(index);
									}}
									className="my-2 bg-gray-100 p-2 cursor-pointer border border-gray-400"
								>
									{e.authority}
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Contacts;
