import React from "react";
import { useEffect } from "react";
import "./ck-content-styles.css";
import "./4cStyles.css";
import axios from "axios";
import { useState } from "react";
import { Buffer } from "buffer";
import { API_BASE_URL, FILE_BASE_URL } from "../../config";
import Footer from "../Footer";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

export default function FourCSubPage({ parentDomainName }) {
	const { subPageName } = useParams();

	const [logo, setLogo] = useState("");
	const [subTitle, setSubTitle] = useState("");
	const [name, setName] = useState("");
	const [rawHTML, setRawHTML] = useState();
	const [topLinks, setTopLinks] = useState([]);

	useEffect(
		() => {
			async function fetch4CSubPagedata() {
				try {
					const response = await axios.get(
						API_BASE_URL +
							`FourCSubpage.php?four_c_subdomain_name=` +
							parentDomainName +
							`&four_c_subpage_name=` +
							subPageName
					);
					const obj = response.data;
					console.log(obj);
					setRawHTML(Buffer.from(obj["subpage_content"], "base64"));
					setLogo(obj["four_c_logo"]);
					setSubTitle(obj["four_c_sub_title"]);
					setName(obj["four_c_name"]);
					setTopLinks(obj["four_c_top_links"]);
				} catch (e) {
					console.error(e);
				}
			}

			fetch4CSubPagedata();
		},
		/*PUT THE 4C SUB DOMAIN HERE*/ []
	);

	var header = <></>;

	if (logo === "" || logo == null) {
		header = (
			<section
				style={{
					justifyContent: "space-between",
					width: "100%",
					backgroundColor: "#9b1200",
					boxShadow: "5px 5px 500px #9b1200",
				}}
			>
				<div class="fourC-header">
					<Link
						to="/"
						style={{
							textDecoration: "none",
							maxWidth: "150px",
						}}
					>
						<img src="https://beta.ptuniv.edu.in/assets/images/ptu-logo.png" />
					</Link>
					<div
						class="fourC-club-name"
						style={{ width: "100%", alignSelf: "center" }}
					>
						<h3>{name}</h3>
						<p>
							<i>Puducherry Technological University</i>
						</p>
					</div>
				</div>
			</section>
		);
	} else {
		header = (
			<section
				style={{
					justifyContent: "space-between",
					width: "100%",
					backgroundColor: "#9b1200",
					boxShadow: "5px 5px 500px #9b1200",
				}}
			>
				<div class="fourC-header">
					<Link to="/" style={{ textDecoration: "none", maxWidth: "150px" }}>
						<img src="https://beta.ptuniv.edu.in/assets/images/ptu-logo.png" />
					</Link>
					<div class="fourC-club-name">
						<h3>{name}</h3>
						<p>
							<i>Puducherry Technological University</i>
						</p>
					</div>
					<Link to="" style={{ textDecoration: "none", maxWidth: "150px" }}>
						<img src={FILE_BASE_URL + "four_c_logos/" + logo}></img>
					</Link>
				</div>
			</section>
		);
	}

	return (
		<div className="justify-center items-center">
			{header}

			<div className="max-w-[1280px] mx-auto">
				<div className="fourC-custom-content">
					<div className="ck-content">
						<div
							dangerouslySetInnerHTML={{
								__html: rawHTML,
							}}
						></div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
