import React, { useState, useEffect } from "react";
import { FILE_BASE_URL, getImageUrl } from "../../config";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import useFetch from "../../hooks/useFetch";

import "./Banner.css";

// eslint-disable-next-line no-unused-vars
const localImages = [
	{ url: getImageUrl("PEC_admin_block.gif") },
	{ url: getImageUrl("ptuInauguration_screen1.jpg") },
	{ url: getImageUrl("ptuInauguration_screen4.jpg") },
	{ url: getImageUrl("pec-auditorium.jpg") },
	{ url: getImageUrl("ptuInauguration.jpg") },
	{ url: getImageUrl("ptuInauguration_vicePresSpeech.jpg") },
];

const firstImageSlide = [
	<div
		className="banner__first-slide"
		style={{
			backgroundImage: `url(${getImageUrl("opt-landing-page2.jpg")})`,
			backgroundSize: "cover",
			backgroundPosition: "center center",
		}}
	>
		<div>
			<div className="banner__text-box">
				<h6>Welcome to</h6>
				<h1>
					PUDUCHERRY
					<br />
					TECHNOLOGICAL
					<br />
					UNIVERSITY
				</h1>
			</div>
		</div>
	</div>,
];

function Banner() {
	const { data } = useFetch("Images.php?category=home_page_slider");

	let [slides, setSlides] = useState(firstImageSlide);

	useEffect(() => {
		console.log(data);
		if (data) {
			const newSlides = firstImageSlide.concat(
				data.map((slide) => (
					<div className="image-gallery__img-container">
						<div className="relative">
							<img
								src={`${FILE_BASE_URL}img_gallery/${slide.img_name}`}
								className="image-gallery__img"
								alt={slide.description ?? ""}
							/>
							{slide.description && (
								<div className="image-gallery__description">
									<p>{slide.description}</p>
								</div>
							)}
						</div>
					</div>
				))
			);
			setSlides(newSlides);
		}
	}, [data]);

	return (
		<div className="image-gallery">
			{slides.length === 1 ? (
				slides[0]
			) : (
				<AliceCarousel
					autoPlay
					autoPlayInterval={2000}
					infinite
					animationDuration={2000}
					items={slides}
					disableDotsControls
				/>
			)}
		</div>
	);
}

export default Banner;
