import React from "react";

import "./CANDCURR.css";

export default function Courses({ value }) {
	return (
		<div className="course">
			<div className="ccParent">
				{value.curriculum.map((c) => {
					return (
						<div className="ccCard shadow-xl">
							<h2 className="cctitle text-2xl font-bold text-red-800">{c.name}</h2>
							<div className="text-center pt-5 block">
								{
									c.syllabusPdfLink.map((e)=>{
										return(
											<>
											<div>
											  <div className="curriculum-temp font-bold flex justify-center text-center">
											     <p className="text-xl text-center">{e.name}</p>
											  </div>
											  <div  className="download text-center my-3">
											     <a href={`${e.link}`} className="bg-red-700 text-white py-1 px-2">
									               Download Curriculum
								                 </a>
											  </div>
											</div>
											
											</>
										)
									})
								}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}
