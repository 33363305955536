import { FILE_BASE_URL, SYLLABUS_BASE_URL } from "../../../config";

import Image from "../../../../src/components/assets/IT_HOD.png";

import Dimg from "../../../../src/components/assets/IT.jpg";

import img1 from "./facility/DSC_0003.jpg";
import img2 from "./facility/DSC_0971.jpg";
import img4 from "./facility/DSC_0987.jpg";
import img5 from "./facility/DSC_0992.jpg";

export const IT = {
	basePath: "/it",

	navLinks: [
		{
			to: "/about",
			text: "DEPARTMENT",
		},
		{
			to: "/faculty",
			text: "FACULTY",
		},
		{
			to: "/syllabus",
			text: "CURRICULUM",
		},
		{
			to: "/research",
			text: "RESEARCH",
		},
		{
			to: "/facilities",
			text: "FACILITIES",
		},
	],

	About: {
		DeptName: "Information Technology",
		ShortName: "IT",
		LongName: "DEPARTMENT OF INFORMATION TECHNOLOGY",

		DeptImg: Dimg,

		count: [
			{
				name: "B.Tech Students",
				count: 194,
			},
			{
				name: "M.Tech IoT Students",
				count: 13,
			},
			{
				name: "Ph.D Scholars",
				count: 20,
			},
		],

		about: [
			"The Department of Information Technology offers Under Graduate programme B.Tech Information Technology, Post Graduate programme M.Tech Internet of Thing (IoT) and Doctoral programme Ph.D. The Department is committed to the preparation of graduates to address the great challenges in the field of Information Technology and to contribute for research and innovation that ensure the sustained growth in Computing.",
			"The Department provides state-of-the-art computing facilities to the students enabling them to stay a step ahead. The courses are designed to combine rigorous academic study and exhaustive practical exposure for students with in-depth lab oriented courses, projects, and internships.",
			"The Department hosts a number of workshops and student managed symposiums. Neura is the official symposium of the department which attracts the participants all over India. The department has well experienced faculty, skilled technical staff and well equipped laboratories. The department also encourages innovative projects and quality research in various interrelated domains. The Department is involved in major research on which faculty members and students work on several areas such as Software Engineering, Image processing, Natural Language processing, Real time systems, Distributed computing, Mobile Networks, Data Mining, Big Data, Machine Learning and Internet of Things.",
		],
		vision: [
			{
				point:
					"To Produce Competent Professionals in the field of Information Technology so as to achieve the Global Innovation through Engineering and Technology",
			},
		],

		Mission: [
			{
				point:
					"To Provide High Quality Education and Training in Information Technology through Advanced Learning Environment with State-of-the Art Facilities and Teaching Methodologies.",
			},
            {
				point:
					"To produce Engineers with a strong practical and theoretical exposure, who are able to contribute to society through innovation enterprise and leadership"		
            },
            {
				point:
					"To enhance the fabric of research in computing through collaborative linkages with industry and academia"
            },
		],

		PEOs: [
			{
				point: (
					<a href={FILE_BASE_URL + "it_Btech_peo24.pdf"} target="_blank">
						B.Tech PEOs
					</a>
				),
			},
			{ point: (
					<a href={FILE_BASE_URL + "it_Mtech_peo24.pdf"} target="_blank">
						M.Tech PEOs
					</a>
				),
            },
		],

		Hod: {
			name: "Dr. V. Geetha",
			designation: "Professor and Head",
			img: Image,
			phnNo: "9443790916",
			email: "hod.it@ptuniv.edu.in",
			content: `Welcome to Department of Information Technology. 
			Our Department imparts excellence in Engineering Education. 
			The Department of  IT strongly believes in building career and enriching young minds.
			The  Department offers the three  courses:
			1)  B.Tech in IT, 
			2)	M. Tech  (IoT),
			3)	Ph.D. 
			I am highly confident that the students of our  Department Students  will show a great level of professional competence.
			Wishing  You success in all your endeavours!`,
		},
	},
	departmentalLinks: [
		{
			text: "Departmental Committies",
			link: FILE_BASE_URL + "departmental-links/it-committees24.pdf",
		},
		{
			text: "Notable Alumni",
			link: FILE_BASE_URL + "departmental-links/it-alumni24.pdf",
		},
		{
			text: "Placement Details",
			link: FILE_BASE_URL + "departmental-links/it-placement24.pdf",
		},
		{
			text: "Medals & Awards",
			link: FILE_BASE_URL + "departmental-links/it-medals-and-awards24.pdf",
		},
		{
			text: "Retired Faculty",
			link: FILE_BASE_URL + "departmental-links/it-retired-faculty24.pdf",
		},
		// {
		// 	text: "Students",
		// 	link: "",
		// },
		{
			text: "Events (Past & Upcoming)",
			link: FILE_BASE_URL + "departmental-links/it-events24.pdf",
		},
	],
	Research: [
		{
			name: "Artificial Intelligence and Machine Learning (AI & ML)",
			img: "",
			shortContent: "Artificial Intelligence",
			longContent: "By 2026, the AI & ML market is projected to exceed USD 700 billion, driven by advancements in ethical AI, federated learning, and adaptive neural networks. Research is focused on achieving greater explainability, enhancing real-time decision systems, and addressing bias in AI models to ensure equitable solutions across diverse domains like healthcare, transportation, and finance.",
		},
		{
			name: "Cloud Computing (CC)",
			img: "",
			shortContent: "",
			longContent: "Forecast to reach USD 800 billion by 2026, cloud computing research is advancing in areas such as serverless computing, quantum-cloud integration, and autonomous resource management. Challenges like enhancing data sovereignty, reducing latency, and optimizing hybrid cloud models remain central to the evolution of the field.",
		},
		{
			name: "Internet of Things (IoT)",
			img: "",
			shortContent: "",
			longContent: "With the IoT market expected to surpass USD 1.5 trillion by 2026, research is heavily focused on ultra-low-power devices, edge AI integration, and secure multi-device communication. Addressing challenges like scalability, real-time analytics, and robust cybersecurity in interconnected ecosystems is critical for IoT’s widespread adoption in smart cities and industrial automation.",
		},
		{
			name: "Big Data Analytics (BDA)",
			img: "",
			shortContent: "",
			longContent: "By 2026, the Big Data Analytics market is projected to reach USD 350 billion, with significant research in real-time distributed systems, data lake optimization, and automated insight generation. Innovations in integrating AI for predictive analytics and ethical frameworks for data privacy are reshaping how organizations derive insights from vast datasets.",
		},
		{
			name: "Information Security (IS)",
			img: "",
			shortContent: "",
			longContent: "As the Information Security market approaches USD 400 billion by 2026, research is concentrated on AI-driven threat mitigation, secure multi-party computation, and post-quantum cryptography. Key focus areas include zero-trust architecture, adaptive security frameworks, and enhancing the resilience of critical infrastructures against sophisticated cyberattacks.",
		},
		{
			name: "Biomedical informatics(BI)",
			img: "",
			shortContent: "",
			longContent: "The global healthcare informatics market size accounted for USD 41.62 billion in 2024 and is predicted to reach around USD 127.21 billion by 2034, growing at a CAGR of 11.82% from 2024 to 2034. Health informatics combines communications, information technology (IT), and health care to enhance patient care and is at the forefront of the medical technological revolution. It can be viewed as a branch of engineering and applied science.",
		},
	],
	curriculum: [
		{
			name: "B.Tech",
			desc: "A Bachelor of Technology is an undergraduate academic degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2020-21",
					link: SYLLABUS_BASE_URL + "BTech_Regulations_2021-Nov2024.pdf",
				},
				{
					name: "AY 2018-19",
					link: SYLLABUS_BASE_URL + "8_BT_IT_1819.pdf",
				},
				{
					name: "AY 2014-15",
					link: SYLLABUS_BASE_URL + "8_BT_IT_1415.pdf",
				},
			],
		},
		{
			name: "M.Tech IOT",
			desc: "A degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2020-21",
					link: SYLLABUS_BASE_URL + "19_MT_IT_IT_2021.pdf",
				},
			],
		},
	],
	facilities: [
		{
			name: "CASIT Laboratory ",
			img: img5,
			content:
				"Equipped with 35 Desktop computers with the configuration of Core 2 Duo, Core i3 and Core i5 processors",
		},
		{
			name: "Software Lab I ",
			img: img4,
			content:
				"Equipped with 35 Desktop computers with the configuration of Core i3 and Core i5 processors",
		},
		{
			name: "Software Lab II ",
			img: img1,
			content: "Equipped with 35 systems with Core i5 processors ",
		},
		{
			name: "IoT Lab ",
			img: img2,
			content:
				"Equipped with 20 numbers of Intel Core i5 systems,  Rasperry Pi and Arduino Uno Boards",
		},
		{
			name: "Hardware Lab ",
			img: img1,
			content:
				"Equipped with Digital Trainer kits, FPGA kits, 8085 and 8086 Microprocessor and 8051 Microcontroller kits",
		},
	],
};
