import Image from "../../../../src/components/assets/MATHS_HOD.jpeg";
import { FILE_BASE_URL, SYLLABUS_BASE_URL } from "../../../config";

export const Maths = {
	basePath: "/maths",

	navLinks: [
		{
			to: "/about",
			text: "DEPARTMENT",
		},
		{
			to: "/faculty",
			text: "FACULTY",
		},
		{
			to: "/syllabus",
			text: "CURRICULUM",
		},
		{
			to: "/research",
			text: "RESEARCH",
		},
		{
			to: "/facilities",
			text: "FACILITIES",
		},
	],

	About: {
		DeptName: "MATHEMATICS",
		ShortName: "MATHS",
		LongName: "DEPARTMENT OF MATHEMATICS",

		DeptImg: "",

		count: [
			{
				name: "Ph.D Scholars",
				count: 8,
			},
		],

		about: [
			`The Department of Mathematics has been functioning right from the inception of the institution in the year 1985. 
			The Department offer courses fulfilling the demands of Undergraduate and Post-graduate students. The Department has highly qualified, experienced and dedicated teachers. The Ph.D Programme is offered by the Department in thrust areas of Mathematics.`,

			`The Department of Mathematics, PTU is a Research Department involved in the following teaching and research activities. At present, the Department has four highly qualified, well experienced faculty members. The Department takes paramount care in updating the syllabi of Mathematics papers as per the requirement of all Engineering Departments, following AICTE norms. 
			The topics taught include Complex Analysis, Linear Algebra, Statistics, Queuing Theory, Stochastic Processes, Numerical Methods, Discrete Mathematics etc, which helps to mould the students in learning and developing mathematical skills.  This helps the students to get good placement both in software and hard-core branch of Engineering.`,
		],

		vision: [
			{
				point:
					"To explore innovative approaches to enhance and expand learning opportunities through the integration of various technologies.",
			},
			{
				point:
					"To build a strong research and teaching environment that responds to the real-time challenges of the industry.",
			},
		],

		Mission: [
			{
				point:
					"To inculcate the blend of competence, aptitude of knowledge and investigate flair through devising an ambient environment for sustainable learning.",
			},
			{
				point:
					"To transform attitude, values, priorities by changing mindset and instill positive outlook for socially conscious intellectual development.",
			},
			{
				point:
					"Incubate, apply and spread innovative ideas to evolve the department as a centre of excellence in thrust areas.",
			},
		],

		PEOs: [
			{
				point: (
					<a href={FILE_BASE_URL + "maths-b.tech.pdf"} target="_blank">
						B.Tech PEOs
					</a>
				),
			},
			{ point: "M.Tech" },
		],

		Hod: {
			name: "Dr.G.AYYAPPAN ",
			designation: "Professor and Head",
			phnNo: "0413-2251407",
			email: "hod.maths@ptuniv.edu.in",
			content: `Welcome to the Department of Mathematics at PTU, Puducherry. The Department of Mathematics was established in the year 1985. The Department seeks to combine excellence in Mathematical education with service to the society. We are having well qualified, dedicated and dynamic faculty, whose expertise spans the range of disciplines in Mathematics stream. Our department maintains active research groups for carrying out collaborative and interdisciplinary research. The department has a distinguished record in both teaching and research. Our vision is to be recognized as innovative and leading Mathematics Department.
			I am confident that the students of Engineering departments of Puducherry Technological University would justify the credibility of Mathematics Department by showing a high level of professional competence in their respective field.
			I wish Best of Luck to all of the students of PTU.`,
			img: Image,
		},
	},

	departmentalLinks: [
		{
			text: "Departmental Committies",
			link: FILE_BASE_URL + "departmental-links/maths-committees.pdf",
		},
		{
			text: "Notable Alumni",
			link: FILE_BASE_URL + "departmental-links/maths-alumni.pdf",
		},
		{
			text: "Placement Details",
			link: FILE_BASE_URL + "departmental-links/maths-placement.pdf",
		},
		{
			text: "Medals & Awards",
			link: FILE_BASE_URL + "departmental-links/maths-medals-and-awards.pdf",
		},
		{
			text: "Retired Faculty",
			link: FILE_BASE_URL + "departmental-links/maths-retired-faculty.pdf",
		},
		// {
		// 	text: "Students",
		// 	link: "",
		// },
		{
			text: "Events (Past & Upcoming)",
			link: FILE_BASE_URL + "departmental-links/maths-events.pdf",
		},
	],
	Research: [],
	curriculum: [
		{
			name: "B.Sc",
			desc: "A Bachelor of Technology is an undergraduate academic degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "BSC_MAT_2021",
					link: SYLLABUS_BASE_URL + "BSC_MAT_2021.pdf",
				},
			],
		},
	],
	facilities: [],
};
