import React from 'react'
import pic1 from "./assets/pic1.png"
import pic2 from "./assets/pic2.png"
import pic3 from "./assets/pic3.png"
import pic4 from "./assets/pic4.png"

function Gallery() {
  return (
    <div className='col-span-2'>
      <div className='flex'>
         <div className='flex items-center'>
              <h1 className='font-semibold px-7 text-red-800 border-b-4 border-red-500'>{""}</h1>
         </div>
         <h1 className='mx-4 font-semibold  text-red-800'>Gallery</h1>
      </div>
      <div className='grid grid-cols-4 justify-evenly'>
          <div className='col-span-2 shadow-xl mx-2 mb-4 overflow-hidden'>
            <img src={pic1} alt="" className='h-fill' />
          </div>
          <div className='col-span-2 shadow-xl mx-2 mb-4 overflow-hidden'>
            <img src={pic1} alt="" className=' h-fill' />
          </div>
          <div className='col-span-2 shadow-xl mx-2 mb-4 overflow-hidden'>
            <img src={pic3} alt="" className=' h-fill' />
          </div>
          <div className='col-span-2 shadow-xl mx-2 mb-4 overflow-hidden'>
            <img src={pic4} alt="" className=' h-fill' />
          </div>
      </div>
    </div>
  )
}

export default Gallery