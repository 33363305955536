import React from 'react'
import { useNavigate }  from 'react-router-dom';
import img1 from "./assets/img1.jpeg"
import img2 from "./assets/img2.jpeg"
import img3 from "./assets/img3.jpeg"
import pic2 from "./assets/pic2.png"

function TopNav() {

  const navigate = useNavigate()

  return (
    <>
     <div className="flex my-9 items-center lg:flex-row flex-col">
        <div className="flex items-center gap-4 pl-16 pr-6">
            <div onClick={()=>{navigate("/")}}>
                <img src={pic2} alt="logo" className='design-club-logo ' />
            </div>
            <div className='text-3xl border-r-4 border-red-800 pr-10'>Puducherry <br/>Technological<br/> University</div>
        </div>
        <div className="flex items-center gap-4 lg:py-0 py-6">
            <div>
               <img src={img1} alt="logo" className='design-club-logo' />
            </div>
            <div className='lg:text-6xl text-roboto'>The Design Club</div>
        </div>
     </div>
     <div className='flex m-4 overflow-hidden'> {/* animation */}
         <div className="mx-5 ">
             <img src={img2} alt="" />
         </div>
         <div className="mx-5  design-club-image">
             <img src={pic2} alt="" className='h-fit'/>
         </div>
         <div className="mx-5 ">
             <img src={img2} alt="" />
         </div>
     </div>
    </>
  )
}

export default TopNav