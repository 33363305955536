import React, { useState } from "react";
import CalendarBox from "../../../components/CalendarBox";
import Footer from "../../../components/Footer";
import { ACADEMICS_FILE_BASE_URL, getProfileImageUrl } from "../../../config";
import { DeanHeaderContainer, navItems } from "../../Dean/components/Header";

import "./Calendar.css";

function Calendar() {
	const [currentTabIndex, setCurrentTabIndex] = useState(0);

	return (
		<>
			<DeanHeaderContainer
				deanImg={getProfileImageUrl("hima.jpg")}
				deanName="Dr.S.Himavathi"
				deanDesignation="Dean (Academic Courses)"
				title={`ACADEMICS`}
				navItems={navItems}
			/>
			<div className="flex justify-center items-center py-7">
				<h1 className="m-3 pt-1 px-9 bg-orange-500 text-center text-2xl"> </h1>
				<h1 className="m-1 py-3 text-center text-2xl font-bold text-red-800">
					CALENDAR
				</h1>
			</div>
			<div className="calendar__container">
				<div className="mb-5 flex-1">
					<div>
						<div className="calendar__tabs flex">
							{[
								{
									text: "Odd Semester",
								},
								{
									text: "Even Semester",
								},
								{
									text: "PKIET calendar",
								},
							].map((tab, index) => {
								const currentTabClass =
									currentTabIndex === index ? "tab--active" : "tab";

								return (
									<h2 key={index}>
										<button
											onClick={() => setCurrentTabIndex(index)}
											className={`${currentTabClass} p-2 mr-2`}
										>
											{tab.text}
										</button>
									</h2>
								);
							})}
						</div>

						{[
							{
								content: (
									<a
										href={
											ACADEMICS_FILE_BASE_URL +
											"odd sem Academic calendar 2021-22.pdf"
										}
										className="underline decoration-current"
									>
										Open Odd Semester calendar PDF
									</a>
								),
							},
							{
								content: (
									<a
										href={
											ACADEMICS_FILE_BASE_URL +
											"Even Semester-Academic Calendar 2021-22.pdf"
										}
										className="underline decoration-current"
									>
										Open Even Semester calendar PDF
									</a>
								),
							},
							{
								content: (
									<a
										href={
											ACADEMICS_FILE_BASE_URL +
											"PKIET Academic calendar -2021-22 .pdf"
										}
										className="underline decoration-current"
									>
										Open PKIET calendar PDF
									</a>
								),
							},
						].map((tabContent, tabContentIndex) => {
							if (currentTabIndex !== tabContentIndex) return null;

							return (
								<div key={tabContentIndex} className="tab-content p-3">
									{tabContent.content}
								</div>
							);
						})}
					</div>
				</div>
				<div className="flex justify-center flex-1">
					<CalendarBox />
				</div>
			</div>
			<Footer />
		</>
	);
}

export default Calendar;
