import React from "react";

import "./ResearchPard.css";

export default function Research({ value }) {
	return (
		<div className="containerr p-10 block xl:grid xl:grid-cols-2 gap-7">
			{value.Research.map((e, index) => {
				return (
					<div key={`research-box${index}`}>
						<div className="boxx flex">
							<>
							  {
								  (index %2 === 0) ?
								         <div className="flex research-individual-box">
								            <ResearchInner value={e} index={index} />
							             </div>
										 :
										 <div className="flex research-individual-box">
							            	<ResearchInner value={e} index={index} />
							             </div>
							  }
							</>
						</div>
					</div>
				);
			})}
		</div>
	);
}

let ResearchInner = ({ value, index }) => {
	return(
		<>
		<div className="px-10 py-5 research-boxxx ">
			<h2>{index + 1}</h2>
			<h3 className="research-title">{value.name}</h3>
			<br />
			<p className="R-para p-1">{value.longContent}</p>
		</div>
		<div style={{"width" : "200px"}} className="research-imgg-container">
			<img className="imgg" src={value.img} alt="" />
		</div>
		</>
	)
}